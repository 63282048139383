<template>
  <div class="container">
    <div class="item item-1"></div>
    <div class="item item-2"></div>
    <div class="item item-3"></div>
    <div class="item item-4"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  transform: translate(-50%, -50%);
}

.item {
  width: 25px;
  height: 25px;
  position: absolute;
}

.item-1 {
  background-color: var(--v-primary-lighten1);
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-animation: item-1_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
  animation: item-1_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.item-2 {
  background-color: var(--v-info-lighten1);
  top: 0;
  right: 0;
  -webkit-animation: item-2_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
  animation: item-2_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.item-3 {
  background-color: var(--v-success-lighten1);
  bottom: 0;
  right: 0;
  z-index: 1;
  -webkit-animation: item-3_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
  animation: item-3_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

.item-4 {
  background-color: var(--v-error-lighten1);
  bottom: 0;
  left: 0;
  -webkit-animation: item-4_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
  animation: item-4_move 1.8s cubic-bezier(0.6, 0.01, 0.4, 1) infinite;
}

@-webkit-keyframes item-1_move {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(0, 25px);
    transform: translate(0, 25px);
  }
  50% {
    -webkit-transform: translate(25px, 25px);
    transform: translate(25px, 25px);
  }
  75% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
}

@keyframes item-1_move {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(0, 25px);
    transform: translate(0, 25px);
  }
  50% {
    -webkit-transform: translate(25px, 25px);
    transform: translate(25px, 25px);
  }
  75% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
}

@-webkit-keyframes item-2_move {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
  50% {
    -webkit-transform: translate(-25px, 25px);
    transform: translate(-25px, 25px);
  }
  75% {
    -webkit-transform: translate(0, 25px);
    transform: translate(0, 25px);
  }
}

@keyframes item-2_move {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
  50% {
    -webkit-transform: translate(-25px, 25px);
    transform: translate(-25px, 25px);
  }
  75% {
    -webkit-transform: translate(0, 25px);
    transform: translate(0, 25px);
  }
}

@-webkit-keyframes item-3_move {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(0, -25px);
    transform: translate(0, -25px);
  }
  50% {
    -webkit-transform: translate(-25px, -25px);
    transform: translate(-25px, -25px);
  }
  75% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
}

@keyframes item-3_move {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(0, -25px);
    transform: translate(0, -25px);
  }
  50% {
    -webkit-transform: translate(-25px, -25px);
    transform: translate(-25px, -25px);
  }
  75% {
    -webkit-transform: translate(-25px, 0);
    transform: translate(-25px, 0);
  }
}

@-webkit-keyframes item-4_move {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
  50% {
    -webkit-transform: translate(25px, -25px);
    transform: translate(25px, -25px);
  }
  75% {
    -webkit-transform: translate(0, -25px);
    transform: translate(0, -25px);
  }
}

@keyframes item-4_move {
  0%,
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0);
  }
  50% {
    -webkit-transform: translate(25px, -25px);
    transform: translate(25px, -25px);
  }
  75% {
    -webkit-transform: translate(0, -25px);
    transform: translate(0, -25px);
  }
}
</style>
