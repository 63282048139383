/* eslint-disable */
import Vue from "vue";

const state = {
  dialog: false,
  snackbar: false,
  overlay: false,
  snackbarData: { msg: "Notification!", color: "error" },
};

const getters = {};

const actions = {
  toggleSnackbar({ commit }, payload) {
    commit("TOGGLE_STATE", { k: "snackbar", v: payload });
  },
  toggleDialog({ commit }, payload) {
    commit("TOGGLE_STATE", { k: "dialog", v: payload });
  },
  toggleOverlay({ commit }, payload) {
    commit("TOGGLE_STATE", { k: "overlay", v: payload });
  },
};

const mutations = {
  TOGGLE_STATE(state, payload) {
    const { k, v } = payload;
    if (v.msg) {
      state.snackbarData.msg = v.msg;
      state.snackbarData.color = v.color || "error";
      state.snackbar = true;
    } else if (typeof v == "boolean") {
      state[k] = v;
    } else {
      state[k] = !state[k];
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
