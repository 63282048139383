<template>
  <v-card class="py-7 px-3">
    <v-toolbar flat>
      <v-toolbar-title class="mx-auto"
        ><h2><Brand /></h2></v-toolbar-title
      ><br />
    </v-toolbar>
    <p class="text-center">
      Already have an account?
      <router-link class="info--text" to="auth">Log in</router-link>
    </p>

    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        @submit.prevent="handleSubmit"
      >
        <v-row>
          <!-- full name -->
          <v-col cols="12">
            <v-text-field
              label="Full name"
              hint="Firstname Othernames Lastname"
              :rules="[rules.required, rules.name]"
              v-model="formInputs.name"
              v-bind="fieldProps"
            ></v-text-field>
          </v-col>

          <!-- email -->
          <v-col cols="12">
            <v-text-field
              label="Email"
              :rules="[rules.required, rules.email]"
              v-model="formInputs.email"
              v-bind="fieldProps"
            ></v-text-field>
          </v-col>

          <!-- password -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Password"
                  name="password"
                  :rules="[rules.required, rules.password.min]"
                  v-model="formInputs.password"
                  :type="show ? 'text' : 'password'"
                  v-bind="fieldProps"
                ></v-text-field>
                <v-checkbox
                  v-model="show"
                  label="Show password"
                  color="primary"
                  value="show"
                  class="d-none d-sm-block"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Confirm"
                  name="password_confirmation"
                  :rules="confirmPasswordRule"
                  v-model="formInputs.password_confirmation"
                  :type="show ? 'text' : 'password'"
                  v-bind="fieldProps"
                ></v-text-field>
                <v-checkbox
                  v-model="show"
                  label="Show password"
                  color="primary"
                  value="show"
                  class="d-block d-sm-none d-md-none d-lg-none"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <!-- terms and conditions -->
          <!-- <v-col cols="12">
            <p class="text-center">
              By clicking <span class="font-weight-bold">REGISTER</span>, you
              agree to our
              <a href="#" target="_blank" class="link">Terms of Service</a>
              and
              <a href="#" target="_blank" class="link">Privacy Policy</a>
            </p>
          </v-col> -->

          <!-- submit -->
          <v-col cols="12">
            <v-btn
              block
              depressed
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading || !valid"
              x-large
              >Register</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from "@/helpers/data/ValidationRules.js";
import fieldProps from "@/helpers/styles/InputFieldProps.js";

import Brand from "@/components/Layout/Brand.vue";

export default {
  name: "Register",

  props: ["loading"],

  components: { Brand },

  data: () => ({
    // helpers
    rules,
    fieldProps,

    // attributes
    valid: true,
    lazy: false,
    show: false,

    // form fields
    formInputs: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
  }),

  computed: {
    confirmPasswordRule() {
      // confirm password validation rule
      return [
        (v) => !!v || "Please confirm your password",
        (v) => v === this.formInputs.password || "Passwords must match.",
      ];
    },
  },

  methods: {
    handleSubmit() {
      this.$emit("submitData", {
        credentials: {
          ...this.formInputs,
          email: this.formInputs.email.trim(),
        },
        path: "/users",
      });
    },
  },

  mounted() {
    this.brandName = process.env.VUE_APP_BRAND_NAME;

    if (this.$route.query.email) {
      this.formInputs.email = this.$route.query.email;
    }
  },
};
</script>
