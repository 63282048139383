<template>
  <div id="container">
    <h1 class="u-xxl">😲</h1>
    <h3>Page not found.</h3>
    <router-link to="/" style="text-decoration: none">
      <v-btn depressed outlined class="mt-5">
        <v-icon left dark>mdi-home</v-icon>Go back home
      </v-btn>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Error",
};
</script>

<style scoped>
.u-xxl {
  font-size: 7rem;
}

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-60%);
}
</style>
