<template>
  <v-card class="mb-7" flat style="background: inherit;">
    <!-- courses forms -->
    <template v-if="formType.isCourses">
      <v-toolbar v-bind="toolbarProps">
        <v-toolbar-title v-if="comp_depts.length == 0"
          ><small class="grey--text">No data available</small></v-toolbar-title
        >
        <v-tabs v-model="tab" show-arrows v-else>
          <v-tab
            v-for="(dept, i) in comp_depts"
            :key="i"
            active-class="black--text font-weight-bold"
            class="text-none"
            >{{ dept.code }}</v-tab
          >
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="tab" style="background: inherit;">
        <v-tab-item v-for="(dept, i) in readData" :key="i">
          <v-form
            ref="form"
            v-model="dept.valid"
            :lazy-validation="lazy"
            class="my-3"
          >
            <v-row v-for="(level, j) in dept.levels" :key="j">
              <v-col cols="12" md="5">
                <v-text-field
                  placeholder="size"
                  :rules="[rules.capacity]"
                  v-bind="fieldProps"
                  dense
                  v-model="readData[i].levels[j].size"
                  type="number"
                  ><div slot="prepend" class="mr-1 my-1">
                    {{ readData[i].levels[j].code }}
                  </div>
                </v-text-field> </v-col
              ><v-col cols="12" md="7">
                <v-file-input
                  placeholder="courses"
                  v-bind="fieldProps"
                  accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :rules="[rules.file, rules.excel]"
                  dense
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  @change="onFileChange($event, i, j)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="text-end mt-6">
            <v-btn
              color="success"
              v-bind="btnProps"
              :disabled="dept.loading"
              :loading="dept.loading"
              @click="handleSubmit(i)"
            >
              done
            </v-btn>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <!-- other forms -->
    <template v-else>
      <v-toolbar v-bind="toolbarProps"
        ><v-toolbar-title
          ><small>Add {{ formMeta.title }}</small></v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <template v-if="formType.upload">
            <v-row>
              <v-col cols="12">
                <v-file-input
                  :placeholder="`Upload ${formMeta.id} data`"
                  v-bind="fieldProps"
                  accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :rules="[rules.required, rules.file, rules.excel]"
                  dense
                  @change="onFileChange"
                ></v-file-input>
              </v-col>
            </v-row>
          </template>

          <template v-if="formType.simple">
            <v-row v-for="(item, index) in formData" :key="index">
              <v-col
                cols="12"
                :md="formMeta.id == 'levels' ? 12 : 6"
                v-for="(val, k, i) in item"
                :key="i"
              >
                <v-text-field
                  :placeholder="`${k} ${k == 'duration' ? '(hours)' : ''}`"
                  :rules="[
                    rules.required,
                    k == 'duration' ? rules.duration : true,
                  ]"
                  v-model="formData[index][k]"
                  v-bind="fieldProps"
                  :type="k == 'duration' ? 'number' : 'text'"
                  dense
                >
                  <v-icon
                    v-if="
                      i == Object.keys(item).length - 1 && formData.length > 1
                    "
                    slot="append-outer"
                    class="mr-1"
                    @click="removeField(index)"
                  >
                    mdi-close
                  </v-icon>
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-btn
                  v-bind="btnProps"
                  class="secondary"
                  @click="addField"
                  v-if="index == formData.length - 1"
                  >add
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-form>
      </v-card-text>

      <v-card-actions class="px-4">
        <v-spacer />
        <v-btn
          color="success"
          v-bind="btnProps"
          :disabled="!valid || loading"
          :loading="loading"
          @click="handleSubmit"
        >
          done
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/helpers/data/ValidationRules.js";
import fieldProps from "@/helpers/styles/InputFieldProps.js";
import toolbarProps from "@/helpers/styles/ToolbarProps.js";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";
import { timeTagsBuilder } from "@/helpers/data/DataBuilder.js";

import XLSX from "xlsx";

export default {
  name: "DynamicForm",

  props: {
    formMeta: {
      type: Object,
      required: true,
    },
    formType: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    // helpers
    rules,
    fieldProps,
    toolbarProps,

    tab: null,
    // panel: [0],

    // attributtes
    valid: true,
    loading: false,

    lazy: false,
    name: "",
    code: "",

    inputsFormat: null,

    formData: [],
    readData: [],

    btnProps: {
      depressed: true,
    },
  }),

  methods: {
    ...mapActions(["updateInfo", "toggleSnackbar"]),

    addField() {
      this.formData.push({ ...this.inputsFormat });
    },

    removeField(index) {
      this.formData.splice(index, 1);
    },

    async handleSubmit(index) {
      this.loading = true;
      if (!isNaN(index)) {
        this.readData[index].loading = true;
      }

      const { resource } = this.formMeta;
      const data = {};

      if (this.formMeta.resource == "time_tags") {
        const tagsData = timeTagsBuilder(this.formData);
        data[resource] = tagsData;
      } else if (this.formType.upload) {
        data[resource] = this.readData;
      } else if (this.formType.isCourses) {
        data[resource] = [this.readData[index]];
      } else {
        data[resource] = this.formData;
      }

      const resp = await useMakeFetch({
        method: "post",
        goTo: "/time_tables",
        useId: true,
        way: `/${this.formMeta.resource}`,
        data: data,
      });

      if (!resp.success) {
        this.loading = false;
        if (!isNaN(index)) {
          this.readData[index].loading = true;
        }
        return;
      }

      this.updateInfo({
        n: this.formMeta.id,
        data: resp.data[resource],
        refresh: this.formType.isCourses,
      });
      this.toggleSnackbar({ msg: "Added!", color: "success" });

      if (this.formMeta.formType.simple) {
        this.formData = [{ ...this.inputsFormat }];
        this.valid = true;
      }
      if (this.formMeta.formType.upload) {
        this.readData = [];
      }

      if (!isNaN(index)) {
        this.readData[index].loading = false;
      }
      this.loading = false;
    },

    onFileChange(file, i, j) {
      // read file and store content as json-string
      if (file) {
        const reader = new FileReader();

        reader.readAsBinaryString(file);

        reader.onload = () => {
          const data = reader.result;

          const workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach((sheetName) => {
            const xlRowObjectArray = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheetName]
            );

            const jsonString = JSON.stringify(xlRowObjectArray);

            if (i >= 0 && j >= 0) {
              this.readData[i].levels[j].courses = JSON.parse(jsonString);
            } else {
              this.readData = JSON.parse(jsonString);
            }
          });
        };

        reader.onerror = (err) => {
          console.log(err);
        };
      }
    },

    buildCoursesData() {
      const levels = [];
      this.comp_levels.forEach((level) => {
        levels.push({ ...level, courses: [] });
      });

      const depts = [];

      this.comp_depts.forEach((dept) => {
        depts.push({
          ...dept,
          department_id: dept.id,
          levels: levels,
          valid: true,
          loading: false,
        });
      });

      this.readData = [...depts];
    },
  },

  computed: {
    comp_depts() {
      if (!this.formType.isCourses) return null;
      return this.$store.getters.getInfo("depts");
    },

    comp_levels() {
      if (!this.formType.isCourses) return null;
      return this.$store.getters.getInfo("levels");
    },
  },

  watch: {
    comp_depts() {
      if (this.formType.isCourses) {
        this.buildCoursesData();
      }
    },

    comp_levels() {
      if (this.formType.isCourses) {
        this.buildCoursesData();
      }
    },
  },

  mounted() {
    if (this.formType.simple) {
      this.inputsFormat = { ...this.formMeta.format };
      this.formData.push({ ...this.inputsFormat });
    } else if (this.formType.isCourses) {
      this.buildCoursesData();
    }
  },
};
</script>
