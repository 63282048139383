<template>
  <div>
    <v-overlay
      :z-index="0"
      :value="overlay"
      v-if="activeTT.status == 'processing'"
      absolute
      color="#FFF"
    >
      <ProcessingAnim />
    </v-overlay>
    <v-overlay :value="overlay" v-else>
      <v-progress-circular
        :size="50"
        :width="3"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div v-if="!overlay">
      <v-card color="holder" tile flat style="margin: 0;">
        <TTEdit />

        <v-tabs
          show-arrows
          v-model="tab"
          background-color="transparent"
          color="primary"
          grow
          hide-slider
          class="px-8"
          height="35"
        >
          <v-tab
            :ripple="false"
            v-for="(tab, i) in tabs"
            :key="i"
            active-class="white black--text font-weight-bold"
            class="rounded-t-lg nav-tab"
            style="border: 1px solid #E0E0E0 !important; border-bottom: none; margin: 0 0.5px"
          >
            <small>{{ tab.name }}</small>
          </v-tab>
        </v-tabs>
      </v-card>

      <div class="px-8 py-10">
        <v-tabs-items v-model="tab" style="background: inherit;">
          <v-tab-item v-for="(tab, i) in tabs" :key="i">
            <TTInfo
              :meta="{ ...tab, data: ttInfo }"
              v-if="!overlay"
              @usingTTInfo="onUsingTTInfo"
              @startAction="handleStartAction"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <v-dialog v-model="dialog.edit" max-width="500px"
        ><DynamicEdit :prepData="actionData" @closeDialog="closeDialog('edit')"
      /></v-dialog>

      <v-dialog v-model="dialog.delete" max-width="290">
        <v-card dark>
          <v-card-title class="text-uppercase font-weight-bold"
            >are you sure?</v-card-title
          >
          <v-card-text
            >Do you really want to delete this record? This process cannot be
            undone.</v-card-text
          >
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="white black--text"
              depressed
              @click="closeDialog('delete')"
              >no</v-btn
            >
            <v-btn :loading="dialog.loading" @click="deleteItemConfirm"
              >yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ProcessingAnim from "@/components/Layout/ProcessingAnim.vue";
import TTInfo from "@/components/Forms/Timetable/TTInfo.vue";
import TTEdit from "@/components/Forms/Timetable/TTEdit.vue";
import DynamicEdit from "@/components/Forms/Timetable/DynamicEdit.vue";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";
import {
  schedulesBuilder,
  roomsTagsBuilder,
  coursesLecturersTagsBuilder,
} from "@/helpers/data/DataBuilder.js";

export default {
  name: "TTs",

  components: {
    TTInfo,
    TTEdit,
    ProcessingAnim,
    DynamicEdit,
  },

  data: () => ({
    tab: null,

    tabs: [
      { id: "depts", name: "departments" },
      { id: "levels", name: "levels" },
      { id: "tags", name: "tags" },
      { id: "rooms", name: "rooms" },
      { id: "lecturers", name: "lecturers" },
      { id: "courses", name: "courses" },
      { id: "timetable", name: "timetable" },
    ],

    dialog: {
      delete: false,
      edit: false,
      loading: false,
    },

    actionData: {},
  }),

  computed: {
    activeTT() {
      const tt = this.$store.getters.getCollection("active_tt");
      return tt;
    },

    ttInfo() {
      const tt_info = this.$store.getters.getInfo();
      return tt_info;
    },

    overlay: {
      set(val) {
        this.$store.dispatch("toggleOverlay", val);
      },
      get() {
        return this.activeTT.status == "processing"
          ? true
          : this.$store.state.layout.overlay;
      },
    },

    comp_rooms() {
      const rooms = this.$store.getters.getInfo("rooms");
      return rooms;
    },

    comp_courses() {
      const courses = this.$store.getters.getInfo("courses");
      return courses;
    },
  },

  methods: {
    ...mapActions(["updateCollection", "updateInfo", "initInfo"]),

    handleStartAction(event) {
      this.dialog[event.action] = true;
      this.actionData = { ...{}, ...event };
    },

    async loadData(whichData, ttId) {
      const response = await useMakeFetch({
        method: "get",
        goTo: "/time_tables",
        useId: ttId ? ttId : true,
        way: `/${whichData}`,
      });

      if (response.success) return response.data[whichData];

      return [];
    },

    populateInfo(target, data, refresh) {
      this.updateInfo({
        n: target,
        data: data,
        refresh: refresh,
      });
    },

    finishLoading() {
      this.overlay = false;
    },

    onUsingTTInfo(event) {
      this.updateCollection({
        cn: "time_tables",
        data: {
          id: this.activeTT.id,
          status: event.data.status,
          act: "change",
        },
      });
      this.updateCollection({
        cn: "active_tt",
        data: { ...this.activeTT, status: event.data.status },
      });
    },

    buildCompleted(obj) {
      this.overlay = true;
      if (!obj.hasUpdated) {
        this.updateCollection({
          cn: "time_tables",
          data: { id: this.activeTT.id, status: "completed", act: "change" },
        });
        this.updateCollection({
          cn: "active_tt",
          data: { ...this.activeTT, status: "completed" },
        });
      }
      const timetable = {
        ...schedulesBuilder({
          days: this.activeTT.days,
          schedules: obj.schedules,
        }),
      };
      this.updateInfo({ n: "timetable", data: timetable });
      this.overlay = false;
    },

    async loadCompletedData(ttId) {
      const resp = await useMakeFetch({
        method: "get",
        goTo: "/time_tables",
        useId: ttId,
        way: "/scheduler",
      });
      if (resp.success)
        this.buildCompleted({
          hasUpdated: true,
          schedules: resp.data.schedules,
        });
    },

    async loadPendingData(ttId) {
      this.initInfo();

      /* load timetable data */
      // departments
      const depts = await this.loadData("departments", ttId);
      if (depts.length > 0) this.populateInfo("depts", depts, true);

      // levels
      const levels = await this.loadData("levels", ttId);
      if (levels.length > 0) this.populateInfo("levels", levels, true);

      // tags
      const tags = await this.loadData("time_tags", ttId);
      if (tags.length > 0) this.populateInfo("tags", tags, true);

      // rooms
      const rooms = await this.loadData("rooms", ttId);
      if (rooms.length > 0) this.populateInfo("rooms", rooms, true);

      // lecturers
      const lecturers = await this.loadData("lecturers", ttId);
      if (lecturers.length > 0) this.populateInfo("lecturers", lecturers, true);

      // courses
      const courses = await this.loadData("courses", ttId);
      if (courses.length > 0) this.populateInfo("courses", courses, true);
    },

    async deleteItemConfirm() {
      this.dialog.loading = true;

      const resp = await useMakeFetch({
        method: "delete",
        goTo: "/time_tables",
        useId: true,
        way: `/${this.actionData.resource}/${this.actionData.item.id ||
          this.actionData.item.level_id ||
          this.actionData.item.room_id}`,
      });

      if (resp.success) {
        this.updateInfo({
          n: this.actionData.id,
          data: resp.data[this.actionData.resource],
          refresh: true,
        });

        this.updateCollection({
          cn: "active_tt",
          data: { ...this.activeTT, status: "pending" },
        });
      }
      this.dialog.loading = false;
      this.closeDialog("delete");
    },

    closeDialog(key) {
      this.dialog[key] = false;
      this.actionData = { ...{} };
    },

    async startInfoRequest(id) {
      if (!this.activeTT.id) return;

      this.overlay = true;
      // pending timetable
      if (this.activeTT.status == "pending") {
        await this.loadPendingData(id);
        this.finishLoading();
      }
      // completed timetable
      else if (this.activeTT.status == "completed") {
        await this.loadPendingData(id);
        await this.loadCompletedData(id);
      }
      this.overlay = false;
    },
  },

  watch: {
    "activeTT.id": {
      async handler(val) {
        await this.startInfoRequest(val);
      },
      deep: true,
    },

    comp_rooms(val) {
      this.populateInfo("rooms_tags", roomsTagsBuilder(val), true);
    },

    comp_courses(val) {
      this.populateInfo(
        "courses_lecturers_tags",
        coursesLecturersTagsBuilder(val),
        true
      );
    },
  },

  async created() {
    this.initInfo();
    this.overlay = true;
    const resp = await useMakeFetch({
      method: "get",
      goTo: "/time_tables",
      useId: this.$route.params.id,
    });

    if (resp.success) {
      this.updateCollection({
        cn: "active_tt",
        data: { ...resp.data.time_tables },
      });
    }
    this.overlay = false;
  },

  async beforeRouteUpdate(to, from, next) {
    next();
  },

  channels: {
    AdminChannel: {
      received(event) {
        const { time_table, data } = event;
        const { id, status } = time_table;
        const { schedules } = data;

        if (id == this.activeTT.id) {
          this.updateCollection({
            cn: "active_tt",
            data: { ...this.activeTT, status: status },
          });
          this.buildCompleted({
            hasUpdated: true,
            schedules: schedules,
          });
        }
      },
    },
  },
};
</script>

<style>
.nav-tab:hover *,
.nav-tab:focus *,
.nav-tab:active * {
  background: none !important;
}
</style>
