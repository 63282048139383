<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
      md="5"
      class="mx-auto d-flex justify-space-around"
      style="margin: 2rem 0"
    >
      <v-card class="mx-auto" flat color="transparent">
        <v-list-item three-line>
          <v-list-item-avatar tile size="80" color="grey" class="rounded mr-5"
            ><v-img
              :src="
                `https://avatars.dicebear.com/api/initials/${
                  activeTT.name ? activeTT.name.split(' ')[0][0] : 'T'
                }.svg`
              "
            ></v-img
          ></v-list-item-avatar>
          <v-list-item-content>
            <Create
              v-if="isEditing"
              :prepData="activeTT"
              isEditing
              @toggleEdit="toggleEdit"
            />
            <v-list-item-title v-else class="mb-1">
              <h3>
                {{ activeTT.name }}
              </h3>
              <small
                ><v-icon
                  class="mr-1"
                  x-small
                  :class="getStatusProp(activeTT.status, 'classProp')"
                  >{{ getStatusProp(activeTT.status, "iconProp") }}</v-icon
                >{{ activeTT.status }}
              </small>
            </v-list-item-title>

            <v-list-item-subtitle v-if="!isEditing"
              ><v-btn small text @click="toggleEdit"
                ><v-icon left>mdi-pencil</v-icon> edit</v-btn
              ></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item></v-card
      >
    </v-col>
  </v-row>
</template>

<script>
import getStatusProp from "@/helpers/styles/GetStatusProp.js";
import Create from "./Create.vue";

export default {
  name: "TTEdit",

  components: { Create },

  data: () => ({
    getStatusProp,

    isEditing: false,
  }),

  computed: {
    activeTT() {
      const tt = this.$store.getters.getCollection("active_tt");
      return tt;
    },
  },

  methods: {
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
  },
};
</script>
