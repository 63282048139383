<template>
  <div>
    <v-overlay :value="overlay" v-if="overlay">
      <v-progress-circular
        :size="50"
        :width="3"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <div v-else>
      <div class="px-8 py-10">
        <h3 class="text-center mb-3">{{ activeTT.name }}</h3>
        <DynamicTimetable :prepData="{ ...timetable }" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from "vuex";
import DynamicTimetable from "@/components/Forms/Timetable/DynamicTimetable.vue";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";
import { schedulesBuilder } from "@/helpers/data/DataBuilder.js";

export default {
  name: "TTs",

  components: {
    DynamicTimetable,
  },

  data: () => ({
    timetable: null,

    overlay: false,
  }),

  computed: {
    activeTT() {
      const tt = this.$store.getters.getCollection("active_tt");
      return tt;
    },
  },

  methods: {
    ...mapActions(["updateCollection", "updateInfo", "initInfo"]),

    buildCompleted(obj) {
      this.overlay = true;
      const timetable = {
        ...schedulesBuilder({
          days: this.activeTT.days,
          schedules: obj.schedules,
        }),
      };
      this.updateInfo({ n: "timetable", data: timetable });
      this.overlay = false;
      this.timetable = timetable;
    },

    async loadCompletedData(ttId) {
      const resp = await useMakeFetch({
        method: "get",
        goTo: "/time_tables",
        useId: ttId,
        way: "/scheduler",
      });
      if (resp.success)
        this.buildCompleted({
          hasUpdated: true,
          schedules: resp.data.schedules,
        });
      this.overlay = false;
    },

    async startInfoRequest(id) {
      if (!this.activeTT.id) return;

      this.overlay = true;
      await this.loadCompletedData(id);
      this.overlay = false;
    },
  },

  watch: {
    "activeTT.id": {
      async handler(val) {
        await this.startInfoRequest(val);
      },
      deep: true,
    },
  },

  async created() {
    this.initInfo();
    this.overlay = true;
    const resp = await useMakeFetch({
      method: "get",
      goTo: "/time_tables",
      useId: this.$route.params.id,
    });

    if (resp.success) {
      this.updateCollection({
        cn: "active_tt",
        data: { ...resp.data.time_tables },
      });
    }
    this.overlay = false;
  },
};
</script>
