const data = {
  depts: {
    id: "depts",
    title: "Departments",
    format: { name: "", code: "" },
    resource: "departments",
    formType: {
      simple: true,
    },
    headers: [
      {
        text: "name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "code", value: "code" },
      { text: "actions", value: "actions", sortable: false, align: "end" },
    ],
    tableType: {
      dataTable: true,
    },
  },

  // levels
  levels: {
    id: "levels",
    title: "Levels",
    format: { code: "" },
    resource: "levels",
    formType: {
      simple: true,
    },
    headers: [
      {
        text: "code",
        align: "start",
        sortable: true,
        value: "code",
      },
      { text: "actions", value: "actions", sortable: false, align: "end" },
    ],
    tableType: {
      dataTable: true,
    },
  },

  // tags
  tags: {
    id: "tags",
    title: "Tags",
    format: { name: "", duration: "" },
    resource: "time_tags",
    formType: {
      simple: true,
    },
    headers: [
      {
        text: "name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "duration",
        value: "duration",
      },
      { text: "actions", value: "actions", sortable: false, align: "end" },
    ],
    tableType: {
      dataTable: true,
    },
  },

  // rooms
  rooms: {
    id: "rooms",
    title: "Rooms",
    resource: "rooms",
    format: { name: "", capacity: "" },

    formType: {
      upload: true,
    },
    headers: [
      {
        text: "name",
        align: "start",
        sortable: true,
        value: "room_name",
      },
      { text: "capacity", value: "room_capacity" },
      { text: "tags", value: "tags", autocomplete: true, autoKey: "tags" },
      { text: "actions", value: "actions", sortable: false, align: "end" },
    ],
    tableType: {
      binding: true,
      dataTable: true,
    },
    bindingKey: "rooms_tags",
  },

  // lecturers
  lecturers: {
    id: "lecturers",
    title: "Lecturers",
    resource: "lecturers",
    special: {
      prependId: "lecturer_",
      target: "lecturer_days",
      value: "schedules",
    },

    formType: {
      upload: true,
    },
    headers: [
      {
        text: "name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "email",
        value: "email",
      },
      {
        text: "days",
        value: "schedules",
        autocomplete: true,
        useDays: true,
      },

      { text: "actions", value: "actions", sortable: false, align: "end" },
    ],
    tableType: {
      binding: true,
      dataTable: true,
    },
    bindingKey: "lecturers",
    uneditable: true,
  },

  // courses
  courses: {
    id: "courses",
    title: "Courses",
    resource: "courses",
    format: { name: "", code: "" },

    formType: {
      isCourses: true,
    },
    headers: [
      {
        text: "name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "code", value: "code" },
      {
        text: "lecturers",
        value: "lecturers",
        autocomplete: true,
        autoKey: "lecturers",
      },
      { text: "tags", value: "time_tags", autocomplete: true, autoKey: "tags" },
      {
        text: "schedules",
        value: "schedules",
        autocomplete: true,
        useDays: true,
      },
      { text: "actions", value: "actions", sortable: false, align: "end" },
    ],
    tableType: {
      multibind: true,
    },
    bindingKey: "courses_lecturers_tags",
  },

  // rooms_tags
  rooms_tags: [],

  // courses_lecturers_tags
  courses_lecturers_tags: [],
};

export default data;
