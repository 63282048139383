import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },

    themes: {
      light: {
        primary: "#000000",
        secondary: "#212121",
        info: "#1E54C5",
        error: "#F25757",
        success: "#11939A",
        accent: "#020202",
        holder: "#F8F9FA",
        tertiary: "#a6c5ff",
      },
    },
  },
});
