var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-0 ma-0"},[(!_vm.tableType.multibind)?_c('v-toolbar',_vm._b({},'v-toolbar',_vm.toolbarProps,false),[_c('v-toolbar-title',[_c('h5',[_vm._v(_vm._s(_vm.tableMeta.title))])]),_c('v-spacer'),(_vm.tableType.dataTable)?_c('v-text-field',_vm._b({attrs:{"placeholder":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'v-text-field',_vm.toolbarSearchProps,false)):_vm._e()],1):_vm._e(),(_vm.tableType.dataTable)?[_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"search":_vm.search,"headers":_vm.tableMeta.headers,"items":_vm.tableMeta.id == 'rooms' || _vm.tableMeta.id == 'lecturers'
          ? _vm.loopData
          : _vm.tableMeta[_vm.tableMeta.id],"sort-by":"calories"},scopedSlots:_vm._u([{key:"item.schedules",fn:function(ref){
          var item = ref.item;
          var header = ref.header;
return [_c('v-autocomplete',_vm._b({attrs:{"items":header.useDays ? _vm.activeTT.days : _vm.tableMeta[item.autoKey],"item-text":"name","item-value":"id","placeholder":header.value,"dense":"","rules":[_vm.rules.multiple],"multiple":"","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var sel = ref.item;
          var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(sel.name))])]):_vm._e(),(index === 1)?_c('small',{staticClass:"grey--text"},[_vm._v(" (+"+_vm._s(item[header.value].length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}},'v-autocomplete',_vm.fieldProps,false))]}},{key:"item.tags",fn:function(ref){
          var item = ref.item;
          var header = ref.header;
return [_c('v-autocomplete',_vm._b({attrs:{"items":_vm.tableMeta.tags,"item-text":"name","item-value":"id","placeholder":header.value,"dense":"","rules":[_vm.rules.multiple],"multiple":"","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
          var sel = ref.item;
          var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(sel.name))])]):_vm._e(),(index === 1)?_c('small',{staticClass:"grey--text"},[_vm._v(" (+"+_vm._s(item[header.value].length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(item[header.value]),callback:function ($$v) {_vm.$set(item, header.value, $$v)},expression:"item[header.value]"}},'v-autocomplete',_vm.fieldProps,false))]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [(!_vm.tableMeta.uneditable)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.performAction({ item: item, action: 'edit' })}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.performAction({ item: item, action: 'delete' })}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_vm._v(" No data available... ")]},proxy:true}],null,false,1330671977)}),(_vm.tableType.binding)?_c('div',{staticClass:"mt-12",staticStyle:{"text-align":"right"}},[_c('v-btn',_vm._b({attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"info"},on:{"click":_vm.submitData}},'v-btn',_vm.btnProps,false),[_vm._v(" save ")])],1):_vm._e()]:_vm._e(),(_vm.tableType.multibind)?_vm._l((_vm.loopData),function(loopItem,i){return _c('div',{key:i},[_c('div',{staticClass:"clickable"},[_c('v-toolbar',_vm._b({on:{"click":function($event){loopItem.show = !loopItem.show}}},'v-toolbar',_vm.toolbarProps,false),[_c('v-toolbar-title',[_c('h5',[_vm._v(" Courses: "),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.comp_depts[i].code)+" ")])])])],1)],1),_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(loopItem.show),expression:"loopItem.show"}],attrs:{"lazy-validation":_vm.lazy},model:{value:(loopItem.valid),callback:function ($$v) {_vm.$set(loopItem, "valid", $$v)},expression:"loopItem.valid"}},[_c('v-simple-table',_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.tableMeta.headers),function(header,j){return _c('th',{key:j,class:("text-" + (header.align))},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tbody',_vm._l((loopItem[_vm.tableMeta.id]),function(bindable,k){return _c('tr',{key:k},_vm._l((_vm.tableMeta.headers),function(header,index){return _c('td',{key:index,class:("align-" + (header.align))},[(header.autocomplete)?_c('v-autocomplete',_vm._b({attrs:{"items":header.useDays
                        ? _vm.activeTT.days
                        : _vm.tableMeta[header.autoKey],"item-text":"name","item-value":"id","placeholder":header.text,"dense":"","rules":[_vm.rules.multiple],"multiple":"","small-chips":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                        var item = ref.item;
                        var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e(),(index === 1)?_c('small',{staticClass:"grey--text"},[_vm._v(" (+"+_vm._s(bindable[header.value].length - 1)+" others) ")]):_vm._e()]}}],null,true),model:{value:(bindable[header.value]),callback:function ($$v) {_vm.$set(bindable, header.value, $$v)},expression:"bindable[header.value]"}},'v-autocomplete',_vm.fieldProps,false)):(header.value == 'actions')?_c('span',[(!_vm.tableMeta.uneditable)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.performAction({
                          item: bindable,
                          action: 'edit',
                        })}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.performAction({
                          item: bindable,
                          action: 'delete',
                        })}}},[_vm._v(" mdi-delete ")])],1):_c('span',[_vm._v(_vm._s(bindable[header.value]))])],1)}),0)}),0)]},proxy:true}],null,true)},'v-simple-table',_vm.simpleTableProps,false)),_c('div',{staticClass:"mt-3 mb-12",staticStyle:{"text-align":"right"}},[_c('v-btn',_vm._b({attrs:{"disabled":loopItem.loading,"loading":loopItem.loading,"color":"info"},on:{"click":function($event){return _vm.submitData(i)}}},'v-btn',_vm.btnProps,false),[_vm._v(" save ")])],1)],1)],1)}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }