import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Auth from "@/views/Auth.vue";
import Start from "@/views/Start.vue";
import TTs from "@/views/TTs.vue";
import TTsView from "@/views/TTsView.vue";
import TTsHome from "@/views/TTsHome.vue";
import Error from "@/views/Error.vue";
import TTsViewHome from "@/views/TTsViewHome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/start",
    name: "Start",
    component: Start,
    meta: {
      authRequired: true,
      sudo: true,
    },
  },
  {
    path: "/tts",
    name: "TTsHome",
    component: TTsHome,
    meta: {
      authRequired: true,
      sudo: true,
    },
  },
  {
    path: "/tts/:id",
    name: "TTs",
    component: TTs,
    props: true,
    meta: {
      authRequired: true,
      sudo: true,
    },
  },
  {
    path: "/view",
    component: TTsViewHome,
    children: [
      {
        name: "TTsView",
        path: ":id",
        props: true,
        component: TTsView,
      },
    ],
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/*",
    name: "Error",
    component: Error,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

// route guards
router.beforeEach((to, from, next) => {
  store.state.auth.isValidated = !!localStorage.getItem("lgn");
  const readonly = !!localStorage.getItem("readonly");

  const isAuthenticated = store.getters.getAuthStatus;

  if (readonly && to.matched.some((record) => record.meta.sudo)) {
    next({
      path: "view",
    });
  } else if (to.matched.some((record) => record.meta.authRequired)) {
    if (isAuthenticated) {
      next();
    } else if (!isAuthenticated && to.name === "TTsView") {
      to.params.id && sessionStorage.setItem("ttId", to.params.id);
      next({ path: "/auth" });
    } else {
      next({
        path: "/auth",
      });
    }
  } else if (["Auth", "Home"].includes(to.name) && isAuthenticated) {
    next({
      path: readonly ? "/view" : "/tts",
    });
  } else {
    next();
  }
});

export default router;
