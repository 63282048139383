<template>
  <v-row>
    <v-col cols="12" v-if="meta.id == 'timetable'"
      ><DynamicTimetable
        :prepData="{ ...meta.data[meta.id] }"
        @schedulerInit="onSchedulerInit"
      />
    </v-col>
    <template v-else>
      <v-col cols="12" md="4" class="mx-auto">
        <DynamicForm
          :formMeta="{ ...preset[meta.id], ...meta.data }"
          :formType="preset[meta.id].formType"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
        v-if="
          meta.data[
            meta.id == 'courses'
              ? 'courses_lecturers_tags'
              : meta.id == 'rooms'
              ? 'rooms_tags'
              : meta.id
          ].length > 0
        "
      >
        <DynamicTable
          :tableMeta="{ ...preset[meta.id], ...meta.data }"
          :tableType="preset[meta.id].tableType"
          @performAction="handlePerformAction"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
import preset from "@/helpers/data/ttpreset.js";
import DynamicForm from "./DynamicForm.vue";
import DynamicTable from "./DynamicTable.vue";
import DynamicTimetable from "./DynamicTimetable.vue";

export default {
  name: "TTInfo",

  props: ["meta"],

  components: { DynamicForm, DynamicTable, DynamicTimetable },

  data: () => ({
    preset,
  }),

  methods: {
    onSchedulerInit(event) {
      this.$emit("usingTTInfo", { data: event.data });
    },

    handlePerformAction(event) {
      const { resource, id, format } = this.preset[this.meta.id];
      this.$emit("startAction", { resource, id, format, ...event });
    },
  },
};
</script>
