<template>
  <div v-if="!readonly && !filteredSchedules" class="text-center">
    <v-btn
      class="success mt-8"
      depressed
      :loading="loading"
      :disabled="loading"
      @click="initScheduler"
      large
      >generate</v-btn
    >
  </div>

  <div v-else-if="readonly && !filteredSchedules" class="text-center">
    <v-alert type="info" text
      >The requested timetable is currently not available...</v-alert
    >
  </div>

  <div v-else>
    <!-- toolbar content -->
    <v-toolbar v-bind="toolbarProps">
      <v-combobox
        v-model="search"
        placeholder="filter by multiple with tabs"
        disable-icon-rotate
        multiple
        small-chips
        v-bind="toolbarSearchProps"
        class="d-none d-sm-block d-md-block d-lg-block"
      >
        <template v-slot:selection="data">
          <v-chip
            :key="JSON.stringify(data.item)"
            small
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
          >
            {{ data.item }}
          </v-chip>
        </template>
      </v-combobox>

      <v-select
        class="d-block d-md-none d-lg-none ml-3"
        v-model="select"
        :items="prepData.daysList"
        item-text="str"
        item-value="val"
        single-line
        hide-details
        v-bind="fieldProps"
        dense
      ></v-select>

      <template v-if="!readonly">
        <v-btn
          class="info mx-3"
          depressed
          :loading="loading"
          :disabled="loading"
          @click="initScheduler"
          >regenerate</v-btn
        >

        <v-btn
          fab
          tile
          small
          depressed
          class="rounded d-none d-sm-block d-md-block d-lg-block"
          :loading="loadingPdf"
          :disabled="loadingPdf"
          @click="generatePdf"
        >
          <v-icon>mdi-download</v-icon></v-btn
        >
      </template>
    </v-toolbar>

    <!-- timetable -->
    <v-sheet
      height="100%"
      width="100%"
      v-for="i in 2"
      :key="i"
      :class="{
        'd-none d-md-block d-lg-block': i == 1,
        'd-block d-md-none d-lg-none': i == 2,
      }"
    >
      <v-calendar
        id="timetable"
        :type="i == 1 ? 'week' : 'day'"
        :first-interval="9"
        first-time="07:30"
        :interval-minutes="30"
        :interval-count="24"
        :interval-format="intervalFormat"
        :weekdays="prepData.weekdays"
        :start="select"
        :now="prepData.now"
        :end="prepData.end"
        :short-weekdays="false"
        :short-intervals="false"
        :events="filteredSchedules"
        @click:event="showEvent($event)"
      >
        <template v-slot:day-label-header="{}"
          ><div style="height: 40px;"></div
        ></template>
        <template v-slot:event="{ event }"
          ><div
            class="pa-2 event-content"
            style="height: 100%; display: flex; justify-content: space-between; flex-direction: column;"
          >
            <div>
              {{ event.course.code }} <br />{{ event.name }} <br />{{
                event.start.split(" ")[1]
              }}-{{ event.end.split(" ")[1] }}
              <br />
              ({{ event.time_tag }})

              <span
                v-for="(lecturer, index) in event.lecturers"
                :key="index"
                class="d-block"
                >{{ lecturer.name }}</span
              >
            </div>

            <div>
              {{ event.room }}
            </div>
          </div></template
        >
      </v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark class="rounded-b-0" flat>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title
                  >{{
                    selectedEvent.course ? selectedEvent.course.code + ": " : ""
                  }}
                  {{ selectedEvent.name }}</v-list-item-title
                >
                <v-list-item-subtitle
                  ><v-icon small>mdi-tag</v-icon>
                  {{ selectedEvent.time_tag }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-toolbar>

          <v-card-text class="px-8 py-5">
            <span v-for="(val, k, index) in selectedInfo" :key="index"
              ><span class="font-weight-bold text-uppercase my-1"
                >{{ k }}: </span
              >{{ val }}
              <br />
            </span>
            <span
              ><span class="font-weight-bold text-uppercase my-1">LEVEL: </span>
              {{ selectedEvent.level }}</span
            >
            <v-divider class="my-4"></v-divider>

            <div>
              <span class="font-weight-bold text-uppercase"
                >LECTURER{{
                  selectedEvent.lecturers && selectedEvent.lecturers.length > 1
                    ? "S"
                    : ""
                }}:
              </span>

              <span
                v-for="(lecturer, index) in selectedEvent.lecturers"
                :key="index"
              >
                {{
                  index > 0
                    ? index == selectedEvent.lecturers.length - 1
                      ? " & "
                      : ", "
                    : ""
                }}{{ lecturer.name }}
              </span>
            </div>

            <v-divider class="my-4"></v-divider>
            <span
              ><span class="font-weight-bold text-uppercase my-1">ROOM: </span>
              {{ selectedEvent.room }}</span
            ><br />
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="selectedOpen = false" small>
              close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </div>
</template>

<script>
import toolbarProps from "@/helpers/styles/ToolbarProps.js";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";
import fieldProps, {
  toolbarSearchProps,
} from "@/helpers/styles/InputFieldProps.js";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default {
  name: "DynamicTimetable",

  props: {
    prepData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    //helpers
    toolbarProps,
    fieldProps,
    toolbarSearchProps,

    loading: false,
    loadingPdf: false,

    search: [],

    select: "2021-03-01",

    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),

  computed: {
    readonly() {
      return !!localStorage.getItem("readonly");
    },

    activeTT() {
      const tt = this.$store.getters.getCollection("active_tt");
      return tt;
    },

    filteredSchedules() {
      if (!this.prepData.schedules || this.prepData.schedules.length == 0)
        return null;

      const search = [];
      if (this.search.length == 0) search.push("");
      else {
        this.search.forEach((element) => {
          search.push(element.toLowerCase());
        });
      }

      return this.prepData.schedules.filter((item) => {
        return search.every(
          (str) =>
            item.name.toLowerCase().match(str) ||
            item.course.code.toLowerCase().match(str) ||
            item.course.name.toLowerCase().match(str) ||
            item.department.code.toLowerCase().match(str) ||
            item.department.name.toLowerCase().match(str) ||
            item.room.toLowerCase().match(str) ||
            item.level.toLowerCase().match(str) ||
            item.time_tag.toLowerCase().match(str) ||
            item.start.toLowerCase().match(str) ||
            item.end.toLowerCase().match(str) ||
            item.lecturers.find((person) =>
              person.name.toLowerCase().match(str)
            )
        );
      });
    },

    selectedInfo() {
      return {
        time: `${
          this.selectedEvent.start ? this.selectedEvent.start.split(" ")[1] : ""
        } - ${
          this.selectedEvent.end ? this.selectedEvent.end.split(" ")[1] : ""
        }`,
        department: `${
          this.selectedEvent.department
            ? this.selectedEvent.department.name
            : ""
        } (${
          this.selectedEvent.department
            ? this.selectedEvent.department.code
            : ""
        })`,
      };
    },
  },

  methods: {
    remove(item) {
      const index = this.search.indexOf(item);
      if (index >= 0) this.search.splice(index, 1);
    },

    intervalFormat(interval) {
      return interval.time;
    },

    async initScheduler() {
      this.loading = true;
      const resp = await useMakeFetch({
        method: "post",
        goTo: "/time_tables",
        useId: true,
        way: "/scheduler",
      });
      if (resp.success) {
        this.$emit("schedulerInit", { data: resp.data });
      }
      this.loading = false;
    },

    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = { ...{}, ...event };
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true), 10)
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    async generatePdf() {
      this.loadingPdf = true;
      var element = document.getElementById("timetable");
      await html2canvas(element)
        .then((canvas) => {
          const pdf = new jsPDF({
            orientation: "p",
            format: "a4",
          });

          const imgData = canvas.toDataURL("image/png");
          const imgProps = pdf.getImageProperties(imgData);
          const pageSize = pdf.internal.pageSize;
          const pdfWidth = pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          // Header
          pdf.addFont("Segoe UI.ttf", "Segoe UI", "normal");
          pdf.setFont("Segoe UI");
          pdf.setFontSize(12);
          pdf.setTextColor(40);
          const header = this.activeTT.name.toUpperCase();
          pdf.text(header, pdfWidth / 2, 7, { align: "center" });

          // Image
          pdf.addImage(imgData, "JPEG", 3, 13, pdfWidth - 6, pdfHeight);

          // Footer
          pdf.setFontSize(8);
          pdf.setTextColor(128, 128, 128);
          const str = "Generated by Timetablr".toUpperCase();
          const textWidth = pdf.getTextWidth(str);
          const pageHeight = pageSize.getHeight();
          pdf.text(str, pdfWidth - textWidth - 3, pageHeight - 2);

          pdf.save(this.activeTT.name + ".pdf");
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
  },

  mounted() {
    this.select = this.prepData.start;
  },
};
</script>

<style scoped>
.event-content * {
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  /* max-height: 3.6em; */
  /* line-height: 1.8em; */
}
</style>
