<template>
  <v-container class="area" fill-height fluid>
    <v-row>
      <v-col
        cols="12"
        sm="7"
        md="5"
        lg="4"
        style="z-index: 2;"
        class="d-flex align-center justify-center mx-auto"
      >
        <Register
          v-if="$route.query.m === 'register'"
          @submitData="onSubmit"
          :loading="loading"
        />
        <Login v-else @submitData="onSubmit" :loading="loading" />
      </v-col>
    </v-row>
    <ul class="circles" style="list-style-type: none;">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

import Login from "@/components/Forms/Auth/Login.vue";
import Register from "@/components/Forms/Auth/Register.vue";

export default {
  name: "Auth",

  components: {
    Login,
    Register,
  },

  data: () => ({
    snackbar: false,
    snackbarMsg: "Error!",

    loading: false,
  }),

  methods: {
    ...mapActions(["userAuth", "toggleSnackbar"]),

    async onSubmit(event) {
      this.loading = true;
      let msg = "Connection error!";

      const resp = await this.userAuth(event).catch((err) => {
        console.log(err);
        this.toggleSnackbar({ msg: msg });
        this.loading = false;
        return false;
      });

      if (resp !== true) {
        msg = resp.message;
        msg = msg[0].toUpperCase() + msg.slice(1).toLowerCase();
        this.toggleSnackbar({ msg: msg });
        this.loading = false;
        return;
      }
    },
  },
};
</script>

<style scoped>
@import "~@/assets/bg-animation.css";
</style>
