<template>
  <v-app-bar app absolute dark tile flat class="navbar" :color="color">
    <TTMenu />

    <v-btn
      v-if="!readonly"
      fab
      tile
      small
      depressed
      class="rounded primary white--text"
      @click="goHome"
      router
      link
      to="/tts/"
      exact
    >
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-spacer />
    <v-toolbar-title>
      <h3 class="ml-3"><Brand :dark="true" /></h3>
    </v-toolbar-title>
    <v-spacer />

    <v-btn
      v-if="!readonly"
      small
      fab
      tile
      depressed
      class="mr-2 rounded primary white--text"
      @click.stop="$emit('showDialog')"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <UserMenu @logout="$emit('showLogoutDialog')" />
  </v-app-bar>
</template>

<script>
import Brand from "./Brand.vue";
import UserMenu from "./UserMenu.vue";
import TTMenu from "./TTMenu.vue";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";

export default {
  name: "NavBar",

  components: {
    Brand,
    UserMenu,
    TTMenu,
  },

  async mounted() {
    const resp = await useMakeFetch({
      goTo: "/time_tables",
      stateVar: "time_tables",
    });

    if (
      resp.success &&
      resp.data.time_tables &&
      resp.data.time_tables.length == 0
    )
      this.$router.push("/start");
  },

  methods: {
    goHome() {
      this.$store.dispatch("updateCollection", {
        cn: "active_tt",
        data: { ...{} },
      });
    },
  },

  computed: {
    readonly() {
      return !!localStorage.getItem("readonly");
    },

    color() {
      return localStorage.getItem("readonly") ? "tertiary" : "primary";
    },
  },
};
</script>

<style scoped>
.navbar {
  position: fixed;
}
</style>
