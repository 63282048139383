<template>
  <v-app id="app">
    <v-snackbar v-model="installAlert" color="info" top timeout="10000">
      Install App?

      <template v-slot:action="{ attrs }">
        <v-btn
          small
          depressed
          color="white info--text"
          class="mr-2"
          v-bind="attrs"
          @click="installer()"
        >
          Install
        </v-btn>
        <v-btn
          small
          depressed
          color="white"
          outlined
          v-bind="attrs"
          @click="installAlert = false"
        >
          Cancel
        </v-btn>
      </template>
    </v-snackbar>

    <TopBar
      v-if="!['/auth', '/start'].includes($route.path)"
      @showDialog="dialog = true"
      @showLogoutDialog="logoutDialog = true"
    />

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialog"
    >
      <CreateTTDialog />
    </v-dialog>

    <v-dialog v-model="logoutDialog" max-width="290">
      <Logout @closeLogoutDialog="logoutDialog = false" />
    </v-dialog>

    <v-main id="main">
      <Snack />

      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import Push from "push.js";
import TopBar from "./components/Layout/TopBar.vue";
import Snack from "./components/Layout/Snack.vue";
import CreateTTDialog from "./components/Layout/CreateTTDialog.vue";
import Logout from "./components/Layout/Logout.vue";

export default {
  name: "App",

  components: {
    TopBar,
    Snack,
    CreateTTDialog,
    Logout,
  },

  data: () => ({
    logoutDialog: false,

    installer: undefined,
    installAlert: false,

    insideApp: true,

    url: "",
  }),

  computed: {
    readonly() {
      return !!localStorage.getItem("readonly");
    },

    dialog: {
      set(val) {
        this.$store.dispatch("toggleDialog", val);
      },
      get() {
        return this.$store.state.layout.dialog;
      },
    },

    isLoggedIn() {
      return this.$store.getters.getAuthStatus;
    },
  },

  methods: {
    ...mapActions(["updateCollection"]),

    connectToCable() {
      console.log("connecting to cable...");
      const tkn = localStorage.getItem("tkn");
      this.$cable.connection.connect(this.url + "?token=" + tkn);
      this.$cable.subscribe({
        channel: this.readonly ? "NotificationChannel" : "AdminChannel",
      });
    },

    sendNotification(msg) {
      if (!this.insideApp && Push.Permission.has()) {
        Push.create("Timetablr", {
          body: msg,
          icon: require("@/assets/logo.png"),
          requireInteraction: true,
          vibrate: [200, 100],
          onClick: function() {
            window.focus();
            this.close();
          },
        });
      } else if (this.insideApp) {
        const audio = new Audio(require("@/assets/notification.mp3"));
        audio.play();
        this.$store.dispatch("toggleSnackbar", {
          msg: msg,
          color: "primary",
        });
      }
    },
  },

  watch: {
    isLoggedIn(val) {
      if (val) {
        this.connectToCable();
      }
    },
  },

  mounted() {
    this.url = process.env.VUE_APP_ACTION_CABLE_URL;
    if (this.isLoggedIn) this.connectToCable();
  },

  created() {
    let installPrompt;

    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      installPrompt = e;
      this.installAlert = true;
    });

    this.installer = () => {
      this.installAlert = false;
      installPrompt.prompt();
      installPrompt.userChoice.then((result) => {
        if (result.outcome === "accepted") {
          console.log("Install accepted!");
        } else {
          console.log("Install denied!");
        }
      });
    };

    window.addEventListener("blur", () => {
      this.insideApp = false;
    });

    window.addEventListener("focus", () => {
      this.insideApp = true;
    });

    // window.addEventListener("beforeunload", (e) => {
    //   if (e) {
    //     e.returnValue = "Sure?";
    //   }

    //   return "Sure?";
    // });
  },

  channels: {
    AdminChannel: {
      connected() {
        console.log("connected to admin cable...");
      },
      rejected() {
        console.log("admin cable rejected us...");
      },
      received(event) {
        const { time_table } = event;
        const { id, name, status } = time_table;
        const msg = name + " is  " + status;

        this.sendNotification(msg);

        this.updateCollection({
          cn: "time_tables",
          data: {
            id: id,
            status: status,
            act: "change",
          },
        });
      },
      disconnected() {
        console.log("disconnected from admin cable...");
      },
    },

    NotificationChannel: {
      connected() {
        console.log("connected to viewer cable...");
      },
      rejected() {
        console.log("viewer cable rejected us...");
      },
      received(event) {
        this.sendNotification(event.message);
      },
      disconnected() {
        console.log("disconnected from viewer cable...");
      },
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&family=Secular+One&display=swap");

@font-face {
  font-family: "Segoe UI Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Regular"), url("./fonts/Segoe UI.woff") format("woff");
}

@font-face {
  font-family: "Segoe UI Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Segoe UI Bold"), url("./fonts/Segoe UI Bold.woff") format("woff");
}

html,
body {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Segoe UI Regular";
}

#app {
  font-family: "ABeeZee", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main {
  /* background: #f6f6f6; #f6f6f6 or #e5eeff */
  /* background-image: url(~@/assets/doodle-bg.png); */
  /* background-repeat: repeat; */
}

/* customize styles */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background: #999;
}
</style>
