<template>
  <v-snackbar
    top
    v-model="snackbar"
    timeout="3000"
    :color="snackbarData.color"
    transition="slide-y-transition"
  >
    {{ snackbarData.msg }}
    <template v-slot:action="{ attrs }">
      <v-icon small v-bind="attrs" @click="toggleSnackbar">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SnackBar",

  computed: {
    snackbar: {
      set(val) {
        this.toggleSnackbar(val);
      },
      get() {
        return this.$store.state.layout.snackbar;
      },
    },

    snackbarData() {
      return this.$store.state.layout.snackbarData;
    },
  },

  methods: {
    ...mapActions(["toggleSnackbar"]),
  },
};
</script>
