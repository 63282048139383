<template>
  <v-card class="py-7 px-3">
    <v-toolbar flat>
      <v-toolbar-title class="mx-auto"
        ><h2><Brand /></h2></v-toolbar-title
      ><br />
    </v-toolbar>
    <p class="text-center">
      New to {{ brandName }}?
      <router-link
        class="info--text"
        :to="{ path: 'auth', query: { m: 'register' } }"
        >Register</router-link
      >
    </p>

    <div class="d-flex justify-center">
      <v-radio-group v-model="userType" row>
        <v-radio label="Admin" value="admin"></v-radio>
        <v-radio label="Student" value="student"></v-radio>
        <v-radio label="Lecturer" value="lecturer"></v-radio>
      </v-radio-group>
    </div>

    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        @submit.prevent="handleSubmit"
      >
        <v-row>
          <!-- email -->
          <v-col cols="12">
            <v-text-field
              label="Email"
              :rules="[rules.required, rules.email]"
              v-model="formInputs.email"
              v-bind="fieldProps"
            ></v-text-field>
          </v-col>

          <!-- password -->
          <v-col cols="12" v-if="isAdmin">
            <v-text-field
              label="Password"
              :rules="[rules.required, rules.password.min]"
              v-model="formInputs.password"
              v-bind="fieldProps"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
              v-on:click:append="show = !show"
            ></v-text-field>

            <!-- forgot password -->
            <div class="text-end">
              <router-link
                to="/forgot"
                style="text-decoration: none"
                class="info--text"
              >
                Forgot Password?</router-link
              >
            </div>
          </v-col>

          <!-- submit -->
          <v-col cols="12" class="mt-2">
            <v-btn
              block
              depressed
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading || !valid"
              x-large
              >Log in</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from "@/helpers/data/ValidationRules.js";
import fieldProps from "@/helpers/styles/InputFieldProps.js";

import Brand from "@/components/Layout/Brand.vue";

export default {
  name: "Login",

  props: ["loading"],

  components: { Brand },

  data: () => ({
    brandName: "",

    // helpers
    rules,
    fieldProps,

    // attributtes
    show: false,
    valid: true,
    lazy: false,

    // form fields
    formInputs: {
      email: "",
      password: "",
    },

    userType: "admin",
  }),

  methods: {
    handleSubmit() {
      this.$emit("submitData", {
        credentials: {
          ...this.formInputs,
          email: this.formInputs.email.trim(),
        },
        path: this.isAdmin ? "/token" : `/${this.userType}/login`,
      });
    },
  },

  mounted() {
    this.brandName = process.env.VUE_APP_BRAND_NAME;

    if (this.$route.query.email) {
      this.formInputs.email = this.$route.query.email;
    }
  },

  computed: {
    isAdmin() {
      return this.userType === "admin";
    },
  },
};
</script>
