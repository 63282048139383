<template>
  <v-form
    ref="form"
    v-model="valid"
    :lazy-validation="lazy"
    @submit.prevent="onSubmit"
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          label="Name"
          placeholder="Enter timetable name..."
          :rules="[rules.required]"
          v-model="formInputs.name"
          v-bind="fieldProps"
          ref="ttname"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="formInputs.days"
          :items="weekdays"
          item-text="name"
          item-value="name"
          label="Days"
          v-bind="fieldProps"
          :rules="[rules.multiple]"
          multiple
          return-object
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="formInputs.days.length > 0 ? 'primary darken-4' : ''"
                >
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="formInputs.kind"
          :items="kindList"
          item-text="disp"
          item-value="val"
          label="Type"
          v-bind="fieldProps"
        ></v-select>
      </v-col>

      <v-col cols="12" class="mt-2 text-end">
        <template v-if="isEditing">
          <v-btn text color="error" class="ml-2 mt-1" @click="deleteTT"
            >delete</v-btn
          >

          <v-btn text class="ml-2 mt-1" @click="close">cancel</v-btn>
          <v-btn
            depressed
            color="info"
            class="ml-2 mt-1"
            :disabled="!valid || loading"
            :loading="loading"
            @click="save"
            >save</v-btn
          >
        </template>
        <v-btn
          v-else
          depressed
          color="primary"
          type="submit"
          :disabled="!valid"
          :loading="loading"
          >Create</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from "@/helpers/data/ValidationRules.js";
import fieldProps from "@/helpers/styles/InputFieldProps.js";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";

export default {
  name: "Create",

  props: {
    prepData: {
      type: Object,
    },
    isEditing: {
      type: Boolean,
    },
  },

  data: () => ({
    // helpers
    rules,
    fieldProps,

    // attributtes
    show: false,
    valid: true,
    lazy: false,
    loading: false,

    // form fields
    formInputs: {},
  }),

  computed: {
    selectsEveryday() {
      return this.formInputs.days.length === this.weekdays.length;
    },
    selectsSomedays() {
      return this.formInputs.days.length > 0 && !this.selectsEveryday;
    },
    icon() {
      if (this.selectsEveryday) return "mdi-close-box";
      if (this.selectsSomedays) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {
    initialise(data) {
      if (data) this.formInputs = { ...{}, ...data };
      else
        this.formInputs = {
          ...{},
          name: "",
          days: this.weekdays.slice(1, this.weekdays.length - 1),
          kind: this.kindList[0].val,
          category: "undergrad",
        };
    },

    toggle() {
      this.$nextTick(() => {
        if (this.selectsEveryday) {
          this.formInputs.days = [];
        } else {
          this.formInputs.days = this.weekdays.slice();
        }
      });
    },

    deleteTT() {
      console.log("deleteTT");
    },

    close() {
      this.$emit("toggleEdit");
    },

    async save() {
      console.log("editing...");
      console.log(this.formInputs);
      this.close();
    },

    async create() {
      const resp = await useMakeFetch({
        method: "post",
        goTo: "/time_tables",
        data: this.formInputs,
      });

      if (resp.success && resp.data.time_table) {
        const new_tt = { ...resp.data.time_table };
        this.$store.dispatch("updateCollection", {
          cn: "time_tables",
          data: new_tt,
        });
        this.$store.dispatch("updateCollection", {
          cn: "active_tt",
          data: new_tt,
        });

        this.$store.dispatch("toggleDialog", false);
        this.$router.push(`/tts/${new_tt.id}`);
        this.formInputs.name = "";
      }
    },

    async onSubmit() {
      this.loading = true;

      if (!this.isEditing) await this.create();
      else await this.save();

      this.loading = false;
    },
  },

  mounted() {
    this.initialise(this.prepData);
    this.$refs.ttname.focus();
  },

  created() {
    this.kindList = [
      { val: "class", disp: "Class" },
      { val: "exam", disp: "Exam" },
    ];
    this.weekdays = [
      { number: 0, name: "Sunday" },
      { number: 1, name: "Monday" },
      { number: 2, name: "Tuesday" },
      { number: 3, name: "Wednesday" },
      { number: 4, name: "Thursday" },
      { number: 5, name: "Friday" },
      { number: 6, name: "Saturday" },
    ];
    this.initialise();
  },
};
</script>
