const props = {
  iconProp: {
    pending: "mdi-circle-slice-3",
    processing: "mdi-refresh",
    completed: "mdi-circle-slice-8",
  },

  classProp: {
    completed: "success--text",
  },
};

const getStatusProp = (status, prop) => {
  if (props[prop] && props[prop][status]) return props[prop][status];
  else return "";
};

export default getStatusProp;
