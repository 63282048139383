<template>
  <v-card>
    <v-card-title class="mb-6"
      ><h4 class="font-weight-bold text-uppercase">
        {{ formTitle }}
      </h4></v-card-title
    >
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        @submit.prevent="handleSubmit"
      >
        <v-row>
          <v-col cols="12" v-for="(val, k, i) in prepData.format" :key="i">
            <v-text-field
              :label="`${k}`"
              :placeholder="`${k} ${k == 'duration' ? '(hours)' : ''}`"
              :rules="[rules.required, k == 'duration' ? rules.duration : true]"
              v-bind="fieldProps"
              v-model="formData[k]"
              :type="k == 'duration' ? 'number' : 'text'"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions class="px-4 mt-6">
      <v-spacer />
      <v-btn v-bind="btnProps" text @click="closeDialog">close</v-btn>
      <v-btn
        color="info"
        v-bind="btnProps"
        :disabled="!valid || loading"
        :loading="loading"
        @click="handleSubmit"
      >
        done
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/helpers/data/ValidationRules.js";
import fieldProps from "@/helpers/styles/InputFieldProps.js";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";
import { convertDuration } from "@/helpers/data/DataBuilder.js";

export default {
  name: "DynamicEdit",

  props: ["prepData"],

  data: () => ({
    rules,
    fieldProps,

    valid: true,
    lazy: false,
    loading: false,

    btnProps: {
      depressed: true,
    },

    formData: {},
  }),

  computed: {
    formTitle() {
      const prepend = "Edit";
      if (!this.prepData.resource) return prepend;
      const titleArr = this.prepData.resource.split("_");
      const lastStr = titleArr[titleArr.length - 1];
      return `${prepend} ${lastStr.slice(0, lastStr.length - 1)}`;
    },
  },

  methods: {
    ...mapActions(["updateInfo", "toggleSnackbar"]),

    closeDialog() {
      this.$emit("closeDialog");
    },

    async handleSubmit() {
      this.loading = true;

      let data;
      if (this.prepData.resource == "time_tags") {
        data = {
          ...{},
          ...this.formData,
          name: this.formData.name,
          duration: convertDuration.toArr(this.formData.duration),
        };
      } else {
        data = { ...{}, ...this.formData };
      }

      const resp = await useMakeFetch({
        method: "put",
        goTo: "/time_tables",
        useId: true,
        way: `/${this.prepData.resource}/${this.prepData.item.id ||
          this.prepData.item.level_id ||
          this.prepData.item.room_id}`,
        data: data,
      });

      if (resp.success) {
        this.updateInfo({
          n: this.prepData.id,
          data: resp.data[this.prepData.resource],
          refresh: true,
        });
        this.toggleSnackbar({ msg: "Edited!", color: "info" });
        this.closeDialog();
      }

      this.loading = false;
    },

    initialise(val) {
      const newBuild = {};

      for (const key in val.item) {
        const newKey = key.split("_");
        const lastKey = newKey[newKey.length - 1];

        if (key == "duration") {
          const durationArr = val.item[key].split(" ");

          const hr = durationArr[0];
          const mins = durationArr[1];
          newBuild[lastKey] = convertDuration.fromArr([
            hr.slice(0, hr.length - 3),
            mins.slice(0, mins.length - 4),
          ]);
        } else {
          newBuild[lastKey] = val.item[key];
        }
      }

      this.formData = { ...{}, ...newBuild };
    },
  },

  watch: {
    prepData(val) {
      this.initialise(val);
    },
  },

  mounted() {
    this.initialise(this.prepData);
  },
};
</script>
