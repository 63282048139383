const colors = [
  "#758BFD", // cornflower blue
  "#DF6702", // chocolate web
  "#59955F", // russian green
  "#9C5D90", // antique fuchsia
  "#5467BA", // han blue
  "#B55338", // medium carmine
  "#5B5E5F", // davys grey
  "#6B705C", // ebony
  "#E63946", // imperial red
  "#457B9D", // celadon blue
  "#1D3557", // prussian blue
  "#6D6875", // old lavender
  "#5E6472", // coral black
  "#3C6E71", // ming
  "#284B63", // indigo dye
  "#4B5842", // rifle green
  "#826AED", // medium slate blue
  "#603A40", // eggplant
  "#698B8C", // steel teal
  "#85768C", // rhythm
  "#A13D63", // maroon x 11
];

export default colors;
