<template>
  <v-card>
    <v-toolbar color="primary" dark flat
      ><v-toolbar-title
        ><h4 class="font-weight-bold text-uppercase">
          Create timetable
        </h4></v-toolbar-title
      ></v-toolbar
    >
    <v-card-text class="py-7">
      <Create />
    </v-card-text>
  </v-card>
</template>

<script>
import Create from "@/components/Forms/Timetable/Create.vue";

export default {
  name: "DialogContent",

  components: {
    Create,
  },
};
</script>
