<template>
  <v-menu
    bottom
    left
    offset-y
    origin="top right"
    transition="scale-transition"
    :close-on-content-click="false"
    nudge-bottom="5"
    min-width="250"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        class="mx-2 font-weight-bold"
        color="white"
        v-bind="attrs"
        v-on="on"
        size="40"
      >
        <span class="primary--text text-uppercase">{{ unameInits }}</span>
      </v-avatar>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-avatar
            class="ma-2 ml-0 font-weight-bold"
            color="primary"
            size="40"
          >
            <span class="white--text text-uppercase">{{ unameInits }}</span>
          </v-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ uname }}</v-list-item-title>
            <v-list-item-subtitle class="mt-1"
              ><small>{{ uemail }}</small></v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense>
        <v-list-item router link to="/user">
          <v-list-item-title>Profile settings</v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>Push notification</v-list-item-title>
          <v-spacer />
          <v-switch v-model="hasPush" @change="togglePush"></v-switch>
        </v-list-item>

        <v-divider />

        <v-list-item @click="$emit('logout')">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import Push from "push.js";

export default {
  name: "UserMenu",

  data: () => ({
    hasPush: false,
    unameInits: "",
    uname: "",
    uemail: "",
  }),

  methods: {
    togglePush() {
      Push.Permission.request(
        () => {
          //granted
          this.hasPush = true;
        },
        () => {
          //denied
          this.hasPush = false;
        }
      );
    },
  },

  mounted() {
    this.hasPush = Push.Permission.has();

    const user = localStorage.getItem("user");
    if (user) {
      const profile = JSON.parse(user);
      this.uname = profile.name;
      this.uemail = profile.email;
      const arr = this.uname.split(" ").slice(0, 2);

      arr.forEach((elem) => {
        this.unameInits += elem[0].toString();
      });
    }
  },
};
</script>
