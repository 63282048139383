<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8"
        ><v-card class="pa-9"
          ><v-card-text
            ><v-row>
              <v-col cols="12" sm="6">
                <h3><Brand /></h3>

                <div class="mt-9" style="line-height: 200%">
                  <h2>Welcome, {{ fname }}!</h2>
                  <h2>Create your first timetable.</h2>
                </div>

                <div class="mt-6 pr-3">
                  <Create />
                </div>
              </v-col>

              <v-col cols="6" class="d-none d-sm-block">
                <v-row class="fill-height" align="center" justify="center">
                  <object
                    width="100%"
                    :data="require('@/assets/timeline-animate.svg')"
                    type="image/svg+xml"
                  ></object>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Brand from "@/components/Layout/Brand.vue";
import Create from "@/components/Forms/Timetable/Create.vue";

export default {
  name: "Start",

  components: {
    Brand,
    Create,
  },

  data: () => ({
    fname: "",
  }),

  mounted() {
    const user = localStorage.getItem("user");
    if (user) {
      this.fname = JSON.parse(user)
        .name.split(" ")[0]
        .toString();
    }
  },
};
</script>
