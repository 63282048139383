<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
    v-model="showMenu"
    nudge-bottom="5"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        fab
        tile
        small
        depressed
        class="mx-2 rounded white--text"
        :class="{ transparent: readonly, primary: !readonly }"
      >
        <v-icon>mdi-apps</v-icon>
      </v-btn>
    </template>

    <v-card
      width="300"
      max-height="80vh"
      class="px-3 py-4"
      style="overflow-y: scroll;"
    >
      <v-text-field
        v-model="search"
        append-outer-icon="mdi-close"
        @click:append-outer="showMenu = false"
        placeholder="Search"
        single-line
        hide-details
        v-bind="fieldProps"
        dense
      ></v-text-field>

      <v-list two-line class="mt-3" nav dense>
        <v-list-item-group :value="activeTT.id" :mandatory="false">
          <template v-for="item in filteredItems">
            <v-list-item
              v-slot="{ toggle }"
              :key="item.id"
              class="mt-2"
              :value="item.id"
              @click="onItemFocus(item.id)"
              router
              link
              :to="`/${readonly ? 'view' : 'tts'}/${item.id}`"
            >
              <template @click="toggle">
                <v-list-item-avatar tile class="rounded">
                  <v-img
                    :src="
                      `https://avatars.dicebear.com/api/initials/${
                        item.name.split(' ')[0][0]
                      }.svg`
                    "
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle
                    ><v-icon
                      class="mr-1"
                      x-small
                      :class="getStatusProp(item.status, 'classProp')"
                      >{{ getStatusProp(item.status, "iconProp") }}</v-icon
                    >{{ item.status }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions } from "vuex";
import fieldProps from "@/helpers/styles/InputFieldProps.js";
import getStatusProp from "@/helpers/styles/GetStatusProp.js";

export default {
  data: () => ({
    // helpers
    fieldProps,
    getStatusProp,

    search: "",
    showMenu: false,
  }),

  computed: {
    readonly() {
      return !!localStorage.getItem("readonly");
    },

    activeTT() {
      const tt = this.$store.getters.getCollection("active_tt");
      return tt;
    },

    timetables() {
      return this.$store.getters.getCollection("time_tables");
    },

    filteredItems() {
      const search = this.search.toLowerCase();
      return this.timetables.filter((item) => {
        return (
          item.name.toLowerCase().match(search) ||
          item.status.toLowerCase().match(search) ||
          item.id.match(search)
        );
      });
    },
  },

  methods: {
    ...mapActions(["updateCollection"]),

    onItemFocus(id) {
      const item = this.timetables.filter((item) => item.id == id)[0];
      if (item) {
        this.updateCollection({ cn: "active_tt", data: { ...item } });
      } else {
        this.updateCollection({ cn: "active_tt", data: { ...this.activeTT } });
      }
      this.showMenu = false;
    },
  },
};
</script>
