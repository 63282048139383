export default {
  // form validation rules
  required: (v) => !!v || "Required.",
  name: (v) => (v && v.length >= 2) || "Invalid name.",
  phone: (v) =>
    (v && /\d{10}/.test(v) && v.length === 10) || "Invalid phone number.",

  email: (v) => /.+@.+\..+/.test(v) || "Invalid e-mail.",
  password: {
    min: (v) => v.length >= 8 || "Min 8 characters.",
  },
  multiple: (v) => v.length > 0 || "Required.",
  money: (v) =>
    /^\$?([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9][0-9])?$/.test(v) ||
    "Invalid money format eg. 10.04 or 5",
  tin: (v) => v.length === 11 || "Invalid TIN.",
  location: (v) =>
    (v && v.length >= 5) || "This field must contain more than 5 characters",

  license: (v) => (v && v.length == 10) || "License must be 10 digits long",
  file: (v) => (v && v.size > 0) || "File is required",
  excel: (v) =>
    /\.(xls[mx]?)$/.test(v ? v.name : "invalid.txt") ||
    "File must be .xlsx or .xls",
  duration: (v) => (v && v > 0 && v <= 12) || "Invalid duration",
  capacity: (v) => (v && v > 0 && /^\d+$/.test(v)) || "Invalid capacity",
};
