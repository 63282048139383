import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import ActionCableVue from "actioncable-vue";

Vue.use(ActionCableVue, {
  debug: process.env.NODE_ENV === "development" ? true : false,
  debugLevel: "error",
  connectImmediately: false,
});

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
