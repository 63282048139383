<template>
  <router-link
    to="/"
    class="text-uppercase"
    :class="{ brand: !small, 'brand-sm': small, dark: dark, light: !dark }"
  >
    {{ brandName }}
  </router-link>
</template>

<script>
export default {
  name: "Brand",

  props: ["small", "dark"],

  data: () => ({
    brandName: "",
  }),

  mounted() {
    this.brandName = process.env.VUE_APP_BRAND_NAME;
  },
};
</script>

<style>
.brand,
.brand-sm {
  /* font-family: "Secular One", sans-serif; */
  text-decoration: none;
}

.light {
  color: var(--v-primary-base) !important;
}

.dark {
  color: white !important;
}
</style>
