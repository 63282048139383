<template>
  <v-container fluid class="fill-height">
    <Banner />
  </v-container>
</template>

<script>
import Banner from "@/components/Layout/Banner.vue";

export default {
  name: "Home",
  components: {
    Banner,
  },
};
</script>
