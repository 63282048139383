<template>
  <v-card class="pa-1">
    <v-card-title class="text-uppercase font-weight-bold"
      >Confirm Logout</v-card-title
    >
    <v-card-text>Are you sure you want to logout?</v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" depressed @click="closeLogoutDialog">No</v-btn>
      <v-btn color="error" text @click="logout">Yes</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Logout",

  methods: {
    ...mapActions(["userAuth"]),

    closeLogoutDialog() {
      this.$emit("closeLogoutDialog");
    },

    async logout() {
      this.$cable.connection.disconnect();
      const loggedOut = await this.userAuth({ logout: true });

      if (loggedOut) {
        this.closeLogoutDialog();
      }
    },
  },
};
</script>
