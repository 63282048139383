<template>
  <v-row>
    <!-- accepted invites -->
    <v-col cols="12" sm="8"
      ><v-data-table
        :headers="headers"
        :items="tableItems"
        item-key="id"
        sort-by="name"
        group-by="department"
        class="elevation-1"
        show-group-by
        :loading="loading.table"
        v-model="selected"
        show-select
      ></v-data-table
    ></v-col>

    <!-- send invite -->
    <v-col cols="12" sm="4">
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        @submit.prevent="sendInvitation"
      >
        <v-select
          :items="depts"
          item-text="name"
          item-value="id"
          placeholder="department"
          :rules="[rules.required]"
          v-bind="fieldProps"
          v-model="deptId"
          dense
        ></v-select>

        <v-select
          :items="levels"
          item-text="code"
          item-value="level_id"
          placeholder="level"
          :rules="[rules.required]"
          v-bind="fieldProps"
          v-model="levelId"
          dense
        ></v-select>

        <v-file-input
          v-if="isBulk"
          placeholder="upload viewers"
          v-bind="fieldProps"
          accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :rules="[rules.file, rules.excel]"
          dense
          prepend-inner-icon="mdi-paperclip"
          prepend-icon=""
          @change="onFileChange"
        ></v-file-input>

        <template v-else>
          <v-text-field
            placeholder="name"
            :rules="[rules.required, rules.name]"
            v-bind="fieldProps"
            v-model="singleInvite.name"
            dense
          ></v-text-field>

          <v-text-field
            placeholder="email"
            :rules="[rules.required, rules.email]"
            v-bind="fieldProps"
            v-model="singleInvite.email"
            dense
          >
          </v-text-field>
        </template>
        <div class="d-flex justify-space-between mt-2 flex-wrap">
          <v-btn
            :text="!isBulk"
            :class="{ 'is-pressed': isBulk }"
            class="mt-2"
            tile
            @click="isBulk = !isBulk"
            >bulk</v-btn
          >

          <v-btn
            :loading="loading.btn"
            color="info"
            :disabled="!valid || loading.btn"
            depressed
            class="mt-2"
            type="submit"
            >send invite</v-btn
          >
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import rules from "@/helpers/data/ValidationRules.js";
import fieldProps from "@/helpers/styles/InputFieldProps.js";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";

import XLSX from "xlsx";

/* eslint-disable */

export default {
  props: {
    ttData: {
      type: Object,
    },
    loadingData: {
      type: Boolean,
    },
    viewers: {
      type: Array,
      required: true,
    },
    depts: {
      type: Array,
    },
    levels: {
      type: Array,
    },
  },

  data: () => ({
    rules,
    fieldProps: { ...fieldProps, class: "mb-3" },

    valid: true,
    lazy: false,

    tableItems: [],
    selected: [],

    loading: {
      table: false,
      btn: false,
    },

    isBulk: true,

    deptId: "",
    levelId: "",

    // single viewer
    singleInvite: {
      name: "",
      email: "",
    },

    // bulk viewers
    bulkInvites: null,

    // headers
    headers: [
      {
        text: "name",
        align: "start",
        value: "name",
        groupable: false,
      },
      {
        text: "email",
        value: "email",
        groupable: false,
      },
      { text: "dept", value: "department", align: "right" },
      { text: "level", value: "level", align: "right" },
    ],
  }),

  methods: {
    onFileChange(file) {
      if (file) {
        console.log(file);
        const reader = new FileReader();

        reader.readAsBinaryString(file);

        reader.onload = () => {
          const data = reader.result;

          const workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach((sheetName) => {
            const xlRowObjectArray = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheetName]
            );

            const jsonString = JSON.stringify(xlRowObjectArray);

            this.bulkInvites = JSON.parse(jsonString);
          });
        };

        reader.onerror = (err) => {
          console.log(err);
        };
      } else {
        console.log("no file");
      }
    },

    async loadData(whichData, ttId) {
      const response = await useMakeFetch({
        method: "get",
        goTo: "/time_tables",
        useId: ttId ? ttId : true,
        way: `/${whichData}`,
      });

      if (response.success) return response.data[whichData];

      return [];
    },

    async sendInvitation() {
      if (!this.$refs.form.validate()) return;

      this.loading.btn = true;

      const data = {
        department_id: this.deptId,
        level_id: this.levelId,
        students: [],
      };

      if (this.isBulk) {
        data.students = [...this.bulkInvites];
      } else {
        data.students.push(this.singleInvite);
      }

      const request = {
        method: "post",
        goTo: "/time_tables",
        useId: this.ttData.id,
        way: "/students",
        data: data,
      };

      const response = await useMakeFetch({ ...request });

      this.loading.btn = false;

      if (response.success) {
        this.tableItems = [...[], ...response.data.students];
        this.toggleSnackbar({ msg: "Invitation sent.", color: "info" });
        this.singleInvite.name = "";
        this.singleInvite.email = "";
      }
    },
  },

  watch: {
    viewers(val) {
      this.tableItems = [...val];
    },

    loadingData(val) {
      this.loading.table = !!val;
    },
  },
};
</script>

<style>
.is-pressed {
  box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px 0 rgba(0, 0, 0, 0.14), inset 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
</style>
