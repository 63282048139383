/* eslint-disable */

import router from "../../router";

const state = {
  isValidated: false,
};

const getters = {
  getAuthStatus: () => state.isValidated,
};

const actions = {
  async userAuth({ commit }, payload) {
    if (!payload.logout) {
      const url = `${process.env.VUE_APP_BACKEND_API_ENDPOINT}`;
      const { credentials, path } = payload;
      const response = await fetch(`${url}${path}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const jsonBody = await response.json();

        if (jsonBody.success) {
          localStorage.setItem("tkn", jsonBody.data.access_token);
          const { user, read_only: readonly } = jsonBody.data;

          localStorage.setItem("user", JSON.stringify(user));
          !!readonly &&
            localStorage.setItem("readonly", JSON.stringify(readonly));

          commit("SET_AUTHENTICATION_STATUS", { status: true, readonly });
          return true;
        } else return jsonBody;
      }
    } else {
      commit("SET_AUTHENTICATION_STATUS", false);
      return true;
    }

    return { success: false, message: "Failed! Try again later." };
  },
};

const mutations = {
  SET_AUTHENTICATION_STATUS(state, value) {
    if (!!value) {
      localStorage.setItem("lgn", "lgn");
      state.isValidated = true;

      if (value.readonly) router.push("/view");
      else router.push("/tts");
    } else {
      localStorage.clear();
      sessionStorage.clear();
      router.history.go();
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
