<template>
  <router-view />
</template>

<script>
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";

export default {
  computed: {
    timetables() {
      return this.$store.getters.getCollection("time_tables");
    },
  },

  async mounted() {
    const ttId = sessionStorage.getItem("ttId");
    if (this.$route.params.id) return;
    else if (ttId) {
      this.$router.push(`/view/${ttId}`);
    } else {
      const response = await useMakeFetch({
        goTo: "/time_tables",
      });
      if (response.success) {
        this.$router.push(`/view/${response.data.time_tables[0].id}`);
      }
    }
  },
};
</script>
