<template>
  <v-app-bar app absolute tile class="authbar">
    <h2><Brand /></h2>

    <v-spacer></v-spacer>

    <v-btn depressed color="primary" router link to="/auth">
      Log in
    </v-btn>
  </v-app-bar>
</template>

<script>
import Brand from "./Brand.vue";

export default {
  name: "AuthBar",

  components: {
    Brand,
  },
};
</script>

<style scoped>
.authbar {
  position: fixed;
  background: white !important;
}
</style>
