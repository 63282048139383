import colors from "./colors.js";

export const convertDuration = {
  toArr: function(num) {
    const i = Math.trunc(num);
    const f = Number((num - i).toFixed(2));

    return [i, f * 60];
  },
  fromArr: function(arr) {
    const hr = parseFloat(arr[0]);
    const min = parseFloat(arr[1]) / 60;

    return (hr + min).toFixed(2);
  },
};

export function timeTagsBuilder(arr) {
  const newArr = [];
  arr.forEach((element) => {
    const n = element.name;
    const d = element.duration;

    newArr.push({ name: n, duration: convertDuration.toArr(d) });
  });
  return newArr;
}

export function roomsTagsBuilder(data) {
  const new_rooms_tags = [];
  data.forEach((element) => {
    new_rooms_tags.push({
      room_id: element.id,
      room_name: element.name,
      room_capacity: element.capacity,
      tags: element.time_tags ? element.time_tags.map((tag) => tag.id) : [],
    });
  });
  return new_rooms_tags;
}

export function coursesLecturersTagsBuilder(data) {
  const depts = [];

  data.forEach((dept, index) => {
    let courses = [];
    dept.levels.forEach((element) => {
      if (element.courses.length !== 0) {
        courses = [...courses, ...element.courses];
      }
    });
    depts.push({
      department_id: dept.department_id,
      department_name: dept.name,
      department_code: dept.code,
      valid: true,
      loading: false,
      show: index == 0,
      courses: courses.map((course) => {
        return { course_id: course.id, ...course };
      }),
    });
  });

  return depts;
}

export function schedulesBuilder(param) {
  const { days, schedules } = param;
  const weekdays = [];
  days.forEach((day) => {
    weekdays.push(parseInt(day.number));
  });

  const lastIndex = weekdays.length - 1;
  weekdays.sort(function(a, b) {
    return a - b;
  });

  const namesOfDays = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };
  const dates = [];
  const daysList = [];
  weekdays.forEach((day) => {
    const date = `2021-${day == 0 ? "02-28" : "03-" + String(day)}`;
    dates.push(date);
    daysList.push({ str: namesOfDays[day], val: date });
  });

  const todayIndex = weekdays.indexOf(parseInt(new Date().getDay()));
  const start = todayIndex >= 0 ? dates[todayIndex] : dates[0];
  const now = dates[0];
  const end = dates[lastIndex];

  const events = [];
  schedules.forEach((item) => {
    const dateIndex = weekdays.indexOf(parseInt(item.day));
    events.push({
      ...item,
      start: dates[dateIndex] + " " + item.start,
      end: dates[dateIndex] + " " + item.end,
      color: colors[Math.floor(Math.random() * colors.length)],
    });
  });

  return {
    weekdays,
    start,
    now,
    end,
    schedules: events,
    daysList,
  };
}
