export default {
  outlined: true,
  required: true,
  // autocomplete: "off",
  // color: "grey",
  // dense: true,
  // solo: true,
  "hide-details": true,
};

export const toolbarSearchProps = {
  "prepend-inner-icon": "mdi-magnify",
  label: "search",
  "append-icon": "",
  clearable: true,
  "single-line": true,
  "hide-details": true,
  "solo-inverted": true,
  flat: true,
  dense: true,
};
