/* eslint-disable */
import Vue from "vue";

const infoPreset = {
  depts: [],
  levels: [],
  tags: [],
  rooms: [],
  lecturers: [],
  courses: [],
  rooms_tags: [],
  courses_lecturers_tags: [],
  timetable: {
    weekdays: [1, 2, 3, 4, 5],
    shedules: [],
    end: "2021-03-5",
    now: "2021-03-1",
  },
};

const state = {
  collection: {
    active_tt: { name: "", status: "" },
    time_tables: [],
  },

  tt_info: {
    ...infoPreset,
  },
};

const getters = {
  getCollection: (state) => (collectionName) => {
    const cn = collectionName;
    if (state.collection[cn]) return state.collection[cn];
    console.error(`No collection with name ${cn}!`);
    return [];
  },

  getInfo: (state) => (infoName) => {
    const n = infoName;
    if (!n) return state.tt_info;
    else if (state.tt_info[n]) return state.tt_info[n];
    console.error(`No info with name ${n}!`);
    return [];
  },
};

const actions = {
  makeFetch: async ({ commit }, payload) => {
    let url = `${process.env.VUE_APP_BACKEND_API_ENDPOINT}`;

    if (payload.goTo) url = `${url}${payload.goTo}`;
    if (payload.useId) {
      const { useId } = payload;
      if (typeof useId == "boolean")
        url = `${url}/${state.collection.active_tt.id}`;
      else if (typeof useId == "string") url = `${url}/${useId}`;
    }
    if (payload.way) url = `${url}${payload.way}`;

    const tkn = localStorage.getItem("tkn");

    const method = payload.method
      ? String(payload.method).toUpperCase()
      : "GET";

    let fetchOptions = {
      method: method,
      headers: {
        Authorization: "Bearer " + tkn,
      },
    };

    const moreHeaders = {
      "Content-Type": "application/json",
    };

    const noBodyReqMethods = ["GET", "HEAD"];
    noBodyReqMethods.includes(method) ||
      (fetchOptions = {
        ...fetchOptions,
        headers: {
          ...fetchOptions.headers,
          ...moreHeaders,
        },
        body: JSON.stringify(payload.data),
      });

    const response = await fetch(url, fetchOptions);

    if (response.ok) {
      const jsonBody = await response.json();

      if (payload.stateVar) {
        commit("SET_COLLECTION", {
          jsonBody,
          target: payload.stateVar,
        });
      }

      return jsonBody;
    }

    console.error("Failed to Fetch");
    return false;
  },

  updateCollection({ commit }, payload) {
    commit("UPDATE_STATE", payload);
  },

  updateInfo({ commit }, payload) {
    commit("UPDATE_INFO", payload);
  },

  initInfo({ commit }) {
    commit("INITIALIZE_INFO");
  },
};

const mutations = {
  SET_COLLECTION(state, payload) {
    const target = payload.target;
    const body = payload.jsonBody.data[target];

    if (!state.collection[target] || state.collection[target].length <= 0) {
      Vue.set(state.collection, target, body);
    } else {
      state.collection[target] = body.slice();
    }
  },

  UPDATE_STATE(state, payload) {
    const { cn, data } = payload;

    if (["string", "boolean", "number"].includes(typeof data)) {
      state.collection[cn] = data;
    } else if (typeof data == "object") {
      if (cn === "active_tt") {
        state.collection[cn] = JSON.parse(JSON.stringify(data));
      } else if (data.act) {
        state.collection[cn].forEach((element, index) => {
          if (element.id === data.id) {
            state.collection[cn][index].status = data.status;
          }
        });
      } else {
        const oldData = JSON.parse(JSON.stringify(state.collection[cn]));

        if (Array.isArray(data)) {
          state.collection[cn] = [...data, ...oldData];
        } else {
          state.collection[cn] = [data, ...oldData];
        }
      }
    }
  },

  UPDATE_INFO(state, payload) {
    const { n, data, refresh } = payload;

    const oldData = state.tt_info[n];
    if (Array.isArray(oldData)) {
      if (refresh) {
        state.tt_info[n] = [...[], ...data];
      } else {
        state.tt_info[n] = [...oldData, ...data];
      }
    } else {
      state.tt_info[n] = { ...{}, ...data };
    }
  },

  INITIALIZE_INFO(state) {
    state.tt_info = { ...{}, ...infoPreset };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
