<template>
  <v-row class="banner px-md-12">
    <v-col cols="10" md="5" class="mx-auto"
      ><v-row align="center" justify="center" style="height: 100%;">
        <div class="info-section text-md-left pl-md-7">
          <h1 class="highlight">
            Automate how you plan timetables.
          </h1>
          <p class="my-5">
            Automate scheduling, manage schedules, and reach new productivity
            peaks. From departments to institutions, the way you schedule your
            tasks is unique—accomplish it all with {{ brandName }}.
          </p>
          <v-row>
            <v-col
              cols="12"
              md="7"
              class="d-none d-md-block d-lg-block"
              v-if="!isLoggedIn"
              ><v-text-field
                placeholder="Email"
                solo
                hide-details
                single-line
                v-model="email"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-btn
                class="d-none d-md-block d-lg-block"
                color="primary"
                @click="getStarted"
                large
                >Get started</v-btn
              >
              <v-btn
                class="d-block d-md-none d-lg-none"
                color="primary"
                block
                x-large
                @click="getStarted"
                >Get started</v-btn
              ></v-col
            >
          </v-row>
        </div>
      </v-row></v-col
    >

    <v-col cols="12" md="7" class="d-none d-md-block d-lg-block pa-8">
      <v-row align="center" justify="center">
        <object
          width="100%"
          :data="require('@/assets/nomad.svg')"
          type="image/svg+xml"
        ></object>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Banner",

  data: () => ({
    brandName: "",

    email: "",
  }),

  computed: {
    isLoggedIn() {
      return this.$store.getters.getAuthStatus;
    },
  },

  methods: {
    getStarted() {
      if (this.isLoggedIn) this.$router.push("/tts");
      else if (this.email !== "")
        this.$router.push({
          path: "auth",
          query: { m: "register", email: this.email },
        });
      else
        this.$router.push({
          path: "auth",
          query: { m: "register" },
        });
    },
  },

  mounted() {
    this.brandName = process.env.VUE_APP_BRAND_NAME;
  },
};
</script>

<style>
.banner {
  height: 92vh;
  overflow: hidden;
  background-position: center;
  margin-top: -1.8rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.highlight {
  font-family: "Secular One", sans-serif;
  letter-spacing: -1.2px;
  color: var(--v-primary-base);
  font-size: 2.7rem;
  line-height: 110%;
}

@media (max-width: 960px) {
  /* below laptop */
  .banner {
    text-align: center;
  }
}

@media (min-width: 960px) {
  /* laptop and above */
  .banner {
    /* padding-left: 1.5rem; */
  }
}
</style>
