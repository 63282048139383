<template>
  <div class="pa-0 ma-0">
    <v-toolbar v-bind="toolbarProps" v-if="!tableType.multibind">
      <v-toolbar-title
        ><h5>{{ tableMeta.title }}</h5></v-toolbar-title
      >
      <v-spacer />
      <v-text-field
        v-if="tableType.dataTable"
        v-model="search"
        placeholder="search"
        v-bind="toolbarSearchProps"
      >
      </v-text-field>
    </v-toolbar>
    <template v-if="tableType.dataTable">
      <v-data-table
        :search="search"
        :headers="tableMeta.headers"
        :items="
          tableMeta.id == 'rooms' || tableMeta.id == 'lecturers'
            ? loopData
            : tableMeta[tableMeta.id]
        "
        sort-by="calories"
        class="elevation-1 mt-3"
      >
        <template v-slot:item.schedules="{ item, header }">
          <v-autocomplete
            v-model="item[header.value]"
            :items="header.useDays ? activeTT.days : tableMeta[item.autoKey]"
            item-text="name"
            item-value="id"
            :placeholder="header.value"
            v-bind="fieldProps"
            dense
            :rules="[rules.multiple]"
            multiple
            small-chips
          >
            <template v-slot:selection="{ item: sel, index }">
              <v-chip small v-if="index === 0">
                <span>{{ sel.name }}</span>
              </v-chip>
              <small v-if="index === 1" class="grey--text">
                (+{{ item[header.value].length - 1 }}
                others)
              </small>
            </template>
          </v-autocomplete>
        </template>

        <template v-slot:item.tags="{ item, header }">
          <v-autocomplete
            v-model="item[header.value]"
            :items="tableMeta.tags"
            item-text="name"
            item-value="id"
            :placeholder="header.value"
            v-bind="fieldProps"
            dense
            :rules="[rules.multiple]"
            multiple
            small-chips
          >
            <template v-slot:selection="{ item: sel, index }">
              <v-chip small v-if="index === 0">
                <span>{{ sel.name }}</span>
              </v-chip>
              <small v-if="index === 1" class="grey--text">
                (+{{ item[header.value].length - 1 }}
                others)
              </small>
            </template>
          </v-autocomplete>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="!tableMeta.uneditable"
            small
            class="mr-2"
            @click="performAction({ item: item, action: 'edit' })"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="performAction({ item: item, action: 'delete' })"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          No data available...
        </template>
      </v-data-table>
      <div style="text-align: right;" class="mt-12" v-if="tableType.binding">
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="info"
          v-bind="btnProps"
          @click="submitData"
        >
          save
        </v-btn>
      </div>
    </template>

    <template v-if="tableType.multibind">
      <div v-for="(loopItem, i) in loopData" :key="i">
        <div class="clickable">
          <v-toolbar
            v-bind="toolbarProps"
            @click="loopItem.show = !loopItem.show"
          >
            <v-toolbar-title
              ><h5>
                Courses:
                <span class="grey--text">{{ comp_depts[i].code }} </span>
              </h5></v-toolbar-title
            >
          </v-toolbar>
        </div>

        <v-form
          v-model="loopItem.valid"
          :lazy-validation="lazy"
          v-show="loopItem.show"
        >
          <v-simple-table v-bind="simpleTableProps">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(header, j) in tableMeta.headers"
                    :key="j"
                    :class="`text-${header.align}`"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bindable, k) in loopItem[tableMeta.id]" :key="k">
                  <td
                    v-for="(header, index) in tableMeta.headers"
                    :key="index"
                    :class="`align-${header.align}`"
                  >
                    <v-autocomplete
                      v-if="header.autocomplete"
                      v-model="bindable[header.value]"
                      :items="
                        header.useDays
                          ? activeTT.days
                          : tableMeta[header.autoKey]
                      "
                      item-text="name"
                      item-value="id"
                      :placeholder="header.text"
                      v-bind="fieldProps"
                      dense
                      :rules="[rules.multiple]"
                      multiple
                      small-chips
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <small v-if="index === 1" class="grey--text">
                          (+{{ bindable[header.value].length - 1 }}
                          others)
                        </small>
                      </template>
                    </v-autocomplete>

                    <span v-else-if="header.value == 'actions'">
                      <v-icon
                        small
                        class="mr-2"
                        @click="
                          performAction({
                            item: bindable,
                            action: 'edit',
                          })
                        "
                        v-if="!tableMeta.uneditable"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        @click="
                          performAction({
                            item: bindable,
                            action: 'delete',
                          })
                        "
                      >
                        mdi-delete
                      </v-icon>
                    </span>

                    <span v-else>{{ bindable[header.value] }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div style="text-align: right;" class="mt-3 mb-12">
            <v-btn
              :disabled="loopItem.loading"
              :loading="loopItem.loading"
              color="info"
              v-bind="btnProps"
              @click="submitData(i)"
            >
              save
            </v-btn>
          </div>
        </v-form>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import toolbarProps from "@/helpers/styles/ToolbarProps.js";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";
import rules from "@/helpers/data/ValidationRules.js";
import fieldProps, {
  toolbarSearchProps,
} from "@/helpers/styles/InputFieldProps.js";

export default {
  name: "DynamicTable",

  props: {
    tableMeta: {
      type: Object,
      required: true,
    },
    tableType: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    //helpers
    toolbarProps,
    rules,
    fieldProps,
    toolbarSearchProps,

    search: "",

    valid: true,
    loading: false,
    lazy: false,
    dialog: false,
    dialogDelete: false,
    btnProps: {
      depressed: true,
    },
    simpleTableProps: {
      class: "elevation-1",
      style: "background: inherit;",
    },

    bindables: {
      rooms_tags: [],
      courses_lecturers_tags: [],
      lecturers: [],
    },
  }),

  methods: {
    ...mapActions(["updateInfo", "toggleSnackbar"]),

    async submitData(index) {
      this.loading = true;

      const key = this.tableMeta.id;
      const target = this.tableMeta.bindingKey;
      const special = this.tableMeta.special;

      if (!isNaN(index)) {
        this.bindables[target][index].loading = true;
      }

      const data = {};
      if (special) {
        data[key] = this.bindables[target].map((item) => {
          return {
            [`${special.prependId}id`]: item.id,
            schedules: [...item.schedules],
          };
        });
      } else {
        data[key] = isNaN(index)
          ? this.bindables[target]
          : this.bindables[target][index][key];
      }

      const resp = await useMakeFetch({
        method: "post",
        goTo: "/time_tables",
        useId: true,
        way: `/${special ? special.target : target}`,
        data: data,
      });

      if (resp.success) {
        this.toggleSnackbar({ msg: "Saved!", color: "info" });
      }

      if (!isNaN(index)) {
        this.bindables[target][index].loading = false;
      }
      this.loading = false;
    },

    performAction(event) {
      this.$emit("performAction", event);
    },
  },

  computed: {
    activeTT() {
      const tt = this.$store.getters.getCollection("active_tt");
      return tt;
    },

    loopData: {
      set(val) {
        this.bindables[this.tableMeta.bindingKey] = val;
      },
      get() {
        return this.$store.getters.getInfo(this.tableMeta.bindingKey);
      },
    },

    comp_depts() {
      if (!this.tableType.multibind) return null;
      return this.$store.getters.getInfo("depts");
    },
  },

  mounted() {
    if (this.tableType.binding || this.tableType.multibind)
      this.bindables[this.tableMeta.bindingKey] = [...[], ...this.loopData];
  },
};
</script>

<style>
.clickable:hover .v-toolbar {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.08) !important;
}

.clickable:active .v-toolbar,
.clickable:focus .v-toolbar {
  background: rgba(0, 0, 0, 0.12) !important;
}
</style>
