<template>
  <div>
    <NavBar
      v-if="isLoggedIn"
      @showLogoutDialog="$emit('showLogoutDialog')"
      @toggleDrawer="$emit('toggleDrawer')"
      @showDialog="$emit('showDialog')"
    />
    <AuthBar v-else />
  </div>
</template>

<script>
import AuthBar from "./AuthBar";
import NavBar from "./NavBar";

export default {
  name: "TopBar",

  components: { AuthBar, NavBar },

  computed: {
    isLoggedIn() {
      return this.$store.getters.getAuthStatus;
    },
  },
};
</script>

<style></style>
