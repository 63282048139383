import store from "../../store";

const useMakeFetch = async (data) => {
  let msg = "Connection error!";

  const resp = await store
    .dispatch("makeFetch", data)
    .then((res) => res)
    .catch((err) => {
      console.log(err);
      store.dispatch("toggleSnackbar", { msg: msg });
      return false;
    });

  if (resp.success) {
    return resp;
  } else {
    if (resp.message) {
      msg = resp.message;
      msg = msg[0].toUpperCase() + msg.slice(1).toLowerCase();
    }

    if (resp.error && resp.error == "expired_token") {
      store.dispatch("userAuth", { logout: true });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    store.dispatch("toggleSnackbar", { msg: msg });
  }

  return false;
};

export default useMakeFetch;
