var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.meta.id == 'timetable')?_c('v-col',{attrs:{"cols":"12"}},[_c('DynamicTimetable',{attrs:{"prepData":Object.assign({}, _vm.meta.data[_vm.meta.id])},on:{"schedulerInit":_vm.onSchedulerInit}})],1):[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"4"}},[_c('DynamicForm',{attrs:{"formMeta":Object.assign({}, _vm.preset[_vm.meta.id], _vm.meta.data),"formType":_vm.preset[_vm.meta.id].formType}})],1),(
        _vm.meta.data[
          _vm.meta.id == 'courses'
            ? 'courses_lecturers_tags'
            : _vm.meta.id == 'rooms'
            ? 'rooms_tags'
            : _vm.meta.id
        ].length > 0
      )?_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('DynamicTable',{attrs:{"tableMeta":Object.assign({}, _vm.preset[_vm.meta.id], _vm.meta.data),"tableType":_vm.preset[_vm.meta.id].tableType},on:{"performAction":_vm.handlePerformAction}})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }