<template>
  <v-container fluid>
    <v-row class="pa-4">
      <v-col cols="12" sm="4" md="3">
        <v-toolbar v-bind="toolbarProps" class="mb-3"
          ><v-toolbar-title
            ><h5 class="text-uppercase">All Timetables</h5></v-toolbar-title
          > </v-toolbar
        ><TTList @ttSelected="onTTSelected"
      /></v-col>

      <v-col cols="12" sm="8" md="9">
        <v-toolbar v-bind="toolbarProps" class="mb-3"
          ><v-toolbar-title
            ><h5 class="text-uppercase">Students</h5></v-toolbar-title
          >
          <v-spacer />
          <v-btn
            depressed
            class="success white--text"
            @click="publish"
            :disabled="ttData.status == 'pending' || isPublishing"
            >{{ isPublishing ? "publishing..." : "publish" }}</v-btn
          >
        </v-toolbar>

        <TTViewers
          :ttData="ttData"
          :loadingData="loadingData"
          :depts="depts"
          :levels="levels"
          :viewers="viewers"
      /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import toolbarProps from "@/helpers/styles/ToolbarProps.js";
import TTList from "@/components/Layout/TTList.vue";
import TTViewers from "@/components/Layout/TTViewers.vue";
import useMakeFetch from "@/helpers/data/MakeFetchWorker.js";

export default {
  name: "TTHome",

  components: { TTList, TTViewers },

  data: () => ({
    toolbarProps,

    ttData: {},
    loadingData: false,

    depts: [],
    levels: [],
    viewers: [],

    isPublishing: false,
  }),

  methods: {
    ...mapActions(["toggleSnackbar"]),

    async loadData(whichData, ttId) {
      const response = await useMakeFetch({
        method: "get",
        goTo: "/time_tables",
        useId: ttId ? ttId : true,
        way: `/${whichData}`,
      });

      if (response.success) return response.data[whichData];

      return [];
    },

    async onTTSelected(event) {
      this.loadingData = true;
      this.ttData = { ...event.data };
      // clear viewers
      this.viewers = [...[]];

      try {
        const { id } = event.data;

        // depts
        const depts = await this.loadData("departments", id);
        this.depts = [...[], ...depts];

        // levels
        const levels = await this.loadData("levels", id);
        this.levels = [...[], ...levels];

        // students
        const viewers = await this.loadData("students", id);
        this.viewers = [...[], ...viewers];
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingData = false;
      }
    },

    async publish() {
      this.isPublishing = true;

      const response = await useMakeFetch({
        method: "post",
        goTo: "/time_tables",
        useId: this.ttData.id,
        way: "/publish",
      }).finally(() => {
        this.isPublishing = false;
      });

      if (response.success) {
        this.toggleSnackbar({ msg: response.data.message, color: "info" });
      }
    },
  },
};
</script>
