<template>
  <div>
    <v-text-field
      v-model="search"
      append-icon="mdi-close"
      @click:append="search = ''"
      placeholder="Search"
      single-line
      hide-details
      v-bind="fieldProps"
      dense
      class="mb-3"
    ></v-text-field>

    <v-card max-height="70vh" style="overflow-y: scroll;" flat>
      <v-list nav dense>
        <v-list-item-group
          mandatory
          v-model="selectedItem"
          active-class="active-item"
        >
          <template v-for="item in filteredItems">
            <v-list-item :key="item.id" class="mt-2" :value="item.id">
              <v-list-item-avatar>
                <v-list-item-icon>
                  <v-icon :class="getStatusProp(item.status, 'classProp')">{{
                    getStatusProp(item.status, "iconProp")
                  }}</v-icon>
                </v-list-item-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small>
                      <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                        >mdi-dots-horizontal</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="$router.push(`/tts/${item.id}`)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item>
                      <v-list-item-title>Publish</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import fieldProps from "@/helpers/styles/InputFieldProps.js";
import getStatusProp from "@/helpers/styles/GetStatusProp.js";

export default {
  data: () => ({
    // helpers
    fieldProps,
    getStatusProp,

    search: "",
    showMenu: false,

    selectedItem: null,
  }),

  computed: {
    activeTT() {
      const tt = this.$store.getters.getCollection("active_tt");
      return tt;
    },

    timetables() {
      return this.$store.getters.getCollection("time_tables");
    },

    filteredItems() {
      const search = this.search.toLowerCase();
      return this.timetables.filter((item) => {
        return (
          item.name.toLowerCase().match(search) ||
          item.status.toLowerCase().match(search) ||
          item.id.match(search)
        );
      });
    },
  },

  methods: {
    onItemSelect(id) {
      const item = this.timetables.filter((item) => item.id == id)[0];
      this.$emit("ttSelected", { data: item });
    },
  },

  watch: {
    // "activeTT.id": {
    //       async handler(val) {
    //         await this.startInfoRequest(val);
    //       },
    //       deep: true,
    //     },

    selectedItem(id) {
      this.onItemSelect(id);
    },
  },
};
</script>

<style scoped>
.active-item {
  background: var(--v-secondary-base);
}

.active-item * {
  color: white !important;
}
</style>
